/*******************************/
/* Default styles
/*******************************/

html,
body {
  height: 100%;
  background-color: #eeeeee;
  color: #444;
}

.site-wrap {
  width: 100%;
  height: 100%;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Bree Serif", serif;
    font-weight: 400;
  }

  p,
  ul,
  a,
  li {
    font-family: "Open Sans", sans-serif;
  }

  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 65px;
    border-bottom: 4px solid #eab60b;
    display: inline-block;
  }

  ul {
    list-style-type: none;
    margin-bottom: 15px;
  }

  li {
    font-size: 20px;
  }

  p {
    font-size: 1.625rem;
  }

  header,
  .footer-row,
  .main-body {
    position: relative;
    width: 80%;
    margin: 0 auto;
  }

  .lotu-container .caption {
    font-style: italic;
  }

  *::selection {
    /* Turns highlights into our brand colors */
    background: #eab60b;
  }

  *::-moz-selection {
    background: #eab60b;
  }

  /*******************************/
  /* Error and success messages
	/*******************************/

  .feedback-message {
    font-size: 16px !important;
    display: inline-block;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
  }

  .error-message {
    background-color: #d80000;
  }

  .success-message {
    background-color: #006300;
  }

  /*******************************/
  /* Icons
	/********************************/

  .fa-clock-o,
  .fa-car,
  .fa-phone-square,
  .main-body .fa-picture-o,
  .main-body .fa-money,
  .main-body .fa-check {
    font-size: 2rem;
    color: #eab60b;
    margin-right: 7px;
    top: 3px;
    position: relative;
  }

  .checkmark-container .checkmark-text {
    display: inline-block;
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 12px;
  }

  /*******************************/
  /* Navigation - You are here x
	/*******************************/

  .logo-container {
    display: inline-block;

    .assistive-text {
      height: 1px;
      width: 1px;
      position: absolute;
      overflow: hidden;
      top: -10px;
    }

    #logo {
      display: inline-block;
      height: 49px;
      width: 250px;
      margin-top: 16px;
    }
  }

  .navbar ul li {
    position: relative;
    float: right;
    margin-left: 14px;
    bottom: 8px;
    font-size: 18px;

    &:last-child {
      margin-left: 0;
    }
  }

  .navbar ul li a {
    color: #7a7a7a;
    padding-bottom: 2px;
    text-decoration: none;
    font-weight: 400;
  }

  .navbar ul li .primary-cta {
    background-color: #eab60b;
    font-family: "Montserrat", sans-serif;
    color: white;
    font-weight: bolder;
    padding: 5px 10px;
    border-radius: 2px;

    &:hover {
      color: white;
      background-color: #cea210;
      border-bottom: 0;
    }
  }

  .navbar ul li .active,
  .navbar ul li a:focus,
  .navbar ul li a:active,
  .navbar ul li a:hover {
    border-bottom: 2px solid #eab60b;
    margin-bottom: -2px;
    color: #444;
  }

  .navbar {
    padding: 0 0;
    margin: 0 0;
    float: right;
    top: 23px;
    position: relative;
  }

  .navbar > li {
    display: inline-block;
    float: left;
    margin: 0 20px;
    padding: 16px 0;
    font-size: 18px;
    font-weight: bolder;
    list-style: none;
  }

  /*******************************/
  /* Main content
	/*******************************/

  .first-section {
    min-height: 700px;
  }

  .front-page-first-text {
    width: 58%;
    display: inline-block;

    p {
      margin-top: 0px;
    }
  }

  .front-page-image {
    margin-top: 80px;
    margin-right: 4%;
    width: 38%;
    border-radius: 370px;
    float: left;
  }

  .second-section {
    min-height: 560px;
    background-color: #e5e5e5;
  }

  .fourth-section {
    background-color: #e5e5e5;
  }

  .third-section {
    min-height: 370px;
    padding-bottom: 60px;

    .initial-text {
      margin-top: 80px;

      h2 {
        font-size: 40px;
        margin-bottom: 10px;
      }
      #btn-component {
        margin-top: 10px;
      }
    }
  }

  .third-section-text-container {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 140px;
    margin-bottom: 140px;
    padding-bottom: 30px;
    padding-top: 40px;
    text-align: center;

    p {
      margin-top: 15px;
      font-size: 20px;
    }

    .button-container {
      margin-top: 10px;
      display: inline-block;
    }
  }

  .third-section img {
    margin-top: 100px;
    width: 49%;
    margin-right: 1%;
  }

  .third-section img:nth-child(2) {
    margin-left: 1%;
    margin-right: 0;
  }

  .third-section .button-container {
    margin-top: 10px;
    display: inline-block;
  }

  .third-section p {
    margin-top: 15px;
    font-size: 20px;
  }

  .second-section .sales-main-text {
    line-height: 150%;
    font-size: 26px;
    margin-bottom: 65px;
    padding-top: 85px;
  }

  .second-section h2 {
    display: inline-block;
    padding-top: 100px;
    font-size: 50px;
    border-bottom: 4px solid #444;
  }

  .second-section .threebytwo {
    position: relative;
    display: inline-block;
    width: 31.5%;
    margin-right: 1.5%;
    height: 160px;
  }

  .threebytwo .fa {
    font-size: 2rem;
    margin-right: 8px;
    color: #eab60b;
  }

  .second-section .threebytwo p {
    position: absolute;
    margin-top: 5px;
  }

  .threebytwo h2 {
    font-size: 30px;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    font-family: "Bree Serif", serif;
    font-weight: 400;
    border-bottom: 0;
    padding-top: 0;
  }

  .second-section p {
    margin-top: 20px;
    font-weight: 400;
    font-size: 20px;
  }

  .front-page h1 {
    font-size: 65px;
    margin-top: 120px;
    margin-bottom: 0px;
    border-bottom: initial;
  }

  .sign-in h1 {
    font-size: 65px;
    margin-top: 210px;
    margin-bottom: 15px;
  }

  .main-body p {
    margin-bottom: 15px;
  }

  /*******************************/
  /* Button
	/*******************************/

  .button-container {
    margin-top: 20px;
  }

  .main-button:visited,
  .main-button {
    padding: 16px;
    border-radius: 3px;
    font-family: "Montserrat", sans-serif;
    border: 2px solid #eab60b;
    background-color: #eab60b;
    font-size: 22px;
    font-weight: 600;
    float: left;
    color: white;
    transition: 0.1s all;
    text-decoration: none;
  }

  .secondary-button {
    padding: 16px;
    font-family: "Montserrat", sans-serif;
    border-bottom: 2px solid #cea210;
    font-size: 20px;
    font-weight: 400;
    display: inline-block;
    margin-left: 30px;
    margin-top: 6px;
    text-decoration: none;
  }

  .main-button:hover,
  .main-button:focus {
    border: 2px solid #cea210;
    background-color: #cea210;
  }

  .secondary-button:hover,
  .secondary-button:focus {
    border-bottom: 2px solid #eab60b;
  }

  /*******************************/
  /* Default page
	/********************************/

  .default-page h1 {
    margin-bottom: 0px;
  }

  .default-page h2 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .default-page p {
    font-size: 20px;
  }

  .default-page .button-container {
    margin-top: 120px;
    display: inline-block;
  }

  .default-page .spurningar-p {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 120px;
  }

  .default-page .button-container .main-button {
    font-size: 20px;
  }

  .default-text-container {
    width: 80%;
  }

  /*******************************/
  /* About page
	/********************************/

  .about-us p,
  .about-us li {
    font-size: 22px;
    line-height: 160%;
  }

  .about-us ul {
    margin: 25px 0;
  }

  .starfsmadur {
    width: 33%;
    margin-right: 2%;
    display: inline-block;
    h3 {
      font-size: 24px;
      margin-bottom: 0px;
    }
    p {
      margin-top: 0;
      font-size: 18px1;
    }
  }

  .starfsmadur img {
    width: 100%;
  }

  /*******************************/
  /* Footer
	/********************************/

  footer {
    margin-top: 50px;
    background-color: #e5e5e5;
    line-height: 160%;
  }

  footer p {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }

  footer a {
    text-decoration: underline;
  }

  footer h2 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .footer-row {
    padding-top: 40px;
    min-height: 200px;
  }

  .footer-item {
    width: 24%;
    margin-right: 1%;
    float: left;
    height: 200px;
    a {
      display: block;
      margin-top: 7px;
    }
  }

  .bottom-footer {
    height: 55px;
    background-color: #e5e5e5;
  }

  .bottom-footer p {
    text-align: center;
    font-size: 14px;
    padding-top: 15px;
    margin-bottom: 15px;
  }

  .cc-logos {
    height: 28px;
  }

  /*******************************/
  /* Forgot + reset 
	/********************************/

  .forgot-password-page .field-name {
    margin-top: 24px;
    margin-top: 20px;
    margin-bottom: 2px;
    font-size: 20px;
  }

  .reset-page .btn,
  .forgot-password-page .btn {
    padding: 8px;
    border-radius: 2px;
    font-family: "Montserrat", sans-serif;
    border: 2px solid #eab60b;
    background-color: #eab60b;
    font-size: 20px;
    font-weight: 600;
    color: white;
    transition: 0.1s all;
    margin-top: 20px;
    margin-bottom: 30px;
    text-decoration: none;
  }

  .reset-page .btn:focus,
  .reset-page .btn:hover,
  .forgot-password-page .btn:focus,
  .forgot-password-page .btn:hover {
    border: 2px solid #cea210;
    background-color: #cea210;
    outline: 0;
    cursor: pointer;
  }

  .reset-page input,
  .forgot-password-page input {
    padding: 15px 20px 2px 4px;
    border: none;
    width: 200px;
    font-size: 22px;
    border-bottom: 2px solid #eab60b;
    background-color: #f9f9f9;
  }

  .reset-page input:valid,
  .forgot-password-page input:valid {
    border-bottom: 2px solid #eab60b;
    background-color: white;
  }

  .reset-page input:focus,
  .forgot-password-page input:focus {
    border-bottom: 2px solid #eab60b;
    background-color: white;
  }

  .reset-page .field-name {
    margin-top: 12px;
    margin-bottom: 5px;
  }

  .reset-form-error-short .fa,
  .reset-form-error .fa {
    margin-right: 7px;
  }

  .reset-form-error div {
    position: absolute;
    left: 230px;
    bottom: 3px;
    color: white;
    background-color: #d82424;
    padding: 10px 15px;
    font-weight: bold;
  }

  .reset-form-error-short div {
    position: absolute;
    left: 230px;
    color: white;
    bottom: 93px;
    background-color: #d82424;
    padding: 10px 15px;
    font-weight: bold;
  }

  @import "../components/site/sales-pages/hero-section.scss";
  @import "../components/site/sales-pages/social-proof.scss";
  @import "../components/site/sales-pages/sales-page.scss";
  @import "../components/site/blog-overview.scss";
  @import "../components/site/sales-pages/faq.scss";
  @import "./site/auth.scss";
  @import "./site/blog.scss";
  @import "./site/select.scss";
  @import "./site/driving-instructors.scss";

  /*******************************/
  /* Alerts
	/********************************/

  .alert {
    padding: 15px 0;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    top: 0;
    z-index: 20;
    transition: all 1s;
  }

  .alert-text-container {
    width: 80%;
    margin: 0 auto;
  }

  .alert h4 {
    margin-top: 0;
    color: inherit;
  }

  .alert .alert-link {
    font-weight: bold;
  }

  .alert > p,
  .alert > ul {
    margin-bottom: 0;
  }

  .alert > p + p {
    margin-top: 5px;
  }

  .alert-dismissable .close,
  .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }

  .alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  }

  .alert-success hr {
    border-top-color: #c9e2b3;
  }

  .alert-success .alert-link {
    color: #2b542c;
  }

  .alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
  }

  .alert-info hr {
    border-top-color: #a6e1ec;
  }

  .alert-info .alert-link {
    color: #245269;
  }

  .alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
  }

  .alert-warning hr {
    border-top-color: #f7e1b5;
  }

  .alert-warning .alert-link {
    color: #66512c;
  }

  .alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  }

  .alert-danger hr {
    border-top-color: #e4b9c0;
  }

  .alert-danger .alert-link {
    color: #843534;
  }

  .skraning-form .has-error {
    border-bottom: 1px solid #a94442;
  }

  .skraning-form .btn-div #submit:disabled {
    color: #efefef;
    background-color: #d3b963;
  }

  .has-error + .skraning-form-error > span {
    position: relative;
    left: 330px;
    bottom: 30px;
    color: white;
    background-color: #d82424;
    padding: 10px 15px;
    font-weight: bold;
  }

  /********************************/
  /* Mobile menu
	/********************************/

  .expand {
    display: block !important;
  }

  .strikethrough {
    text-decoration: line-through;
  }

  .mobile-only {
    display: none;
  }

  .sign-up-cta {
    margin-top: 9px;
    margin-right: 75px;
  }

  /*******************************/
  /* Media Queries
	/********************************/

  @media only screen and (max-width: 1600px) {
    .first-section h1 {
      margin-top: 70px;
    }
  }

  @media only screen and (max-width: 1115px) {
    .navbar {
      display: none;
    }

    .mobile-only {
      display: initial;
    }

    .front-page-image {
      margin-top: 140px;
    }

    .front-page-image {
      width: 100%;
      margin-right: 0;
      margin-bottom: 80px;
      margin-top: 0px;
    }

    .front-page-first-text {
      width: 90%;
      margin-top: 70px;
      margin-bottom: 110px;
    }

    .footer-item {
      width: 32%;
    }

    .footer-item:nth-child(3) {
      display: none;
    }

    .second-section .threebytwo {
      width: 49%;
      margin: 0;
    }

    // Making a sticky header with this
    header {
      position: fixed;
      border-bottom: 1px solid #d8d8d8;
      background-color: #eeeeee;
      z-index: 99;
      width: 100%;
    }

    .main-body {
      padding-top: 20px;
    }

    .logo-container #logo {
      height: 40px;
      width: 200px;
      margin-top: 3px;
      margin-left: 8px;
    }

    .first-section h1 {
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 770px) {
    .footer-row {
      width: 95%;
    }

    .footer-item {
      width: 100%;
      padding-bottom: 20px;
      height: initial;
      float: none;
    }

    // make the footer top-padding smaller on mobile due to less space.
    .footer-row {
      padding-top: 20px;
    }

    .second-section .threebytwo {
      width: 99%;
      margin: 0;
    }

    .main-body {
      width: 95%;
    }

    .starfsmadur {
      width: 100%;
    }
  }

  @media only screen and (max-width: 700px) {
    .third-section img {
      margin-top: 100px;
      width: 100%;
    }

    .third-section img:nth-child(2) {
      display: none;
    }

    .navbar {
      display: none;
    }

    .first-section {
      height: 400px;
    }

    .first-section h1 {
      margin-top: 30px;
      font-size: 40px;
    }

    .front-page-first-text {
      margin-top: 20px;
    }

    .front-page-first-text p {
      font-size: 20px;
    }

    .checkmark-container .checkmark-text {
      font-size: 18px;
    }

    .third-section-text-container {
      margin-top: 80px;
      margin-bottom: 80px;
    }

    .second-section .sales-main-text {
      font-size: 22px;
    }

    .second-section .threebytwo h2 {
      font-size: 25px;
    }

    .second-section .threebytwo p {
      font-size: 18px;
    }

    .second-section .threebytwo {
      height: 140px;
    }
  }

  @media only screen and (max-width: 450px) {
    .logo-container #logo {
      width: 175px;
      height: auto;
      margin-top: 6px;
      margin-bottom: 4px;
    }
  }

  @media only screen and (max-width: 391px) {
    .select-search-container,
    .default-text-container,
    .sign-in {
      max-width: 98%;
      width: 98%;
    }

    .logo-container #logo {
      width: 130px;
      height: auto;
      margin-top: 10px;
    }

    .sign-up-cta {
      margin-right: 50px;
    }

    .secondary-button {
      display: none;
    }

    .bottom-footer {
      height: 80px;
    }

    .sign-in .sign-in-container {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    h1,
    .sign-in h1 {
      margin-top: 40px;
      margin-bottom: 5px;
      font-size: 2.5em;
    }
  }
}
