.mg-top-10 {
	margin-top: 10px;
}
.mg-top-20 {
	margin-top: 20px;
}
.mg-top-30 {
	margin-top: 30px;
}
.mg-bottom-10 {
	margin-bottom: 10px;
}
.mg-bottom-20 {
	margin-bottom: 20px;
}
.mg-bottom-30 {
	margin-bottom: 30px;
}
