.mobile-menu {
  .assistive-text {
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -10px;
  }

  #body-nav {
    position: fixed;
    overflow: hidden;
    top: 0;
    background: transparent;
    text-align: left;
    display: none;
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: background 500ms ease;
    -moz-transition: background 500ms ease;
    transition: background 500ms ease;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 0;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .hide-nav #body-nav {
    background: transparent;
    height: 100%;
    width: 100%;
    z-index: -400;
  }

  #body-nav ul {
    padding-left: 0;
    list-style: none;
    position: absolute;
    left: 0%;
    width: 100%;
    top: 40%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateY(-50%);
  }

  #body-nav ul li a {
    display: none;
    text-decoration: none !important;
    height: 50px;
    line-height: 100px;
    font-size: 35px;
    font-weight: 800;
    opacity: 0;
    border: none;
    font-family: "Montserrat", sans-serif;
    color: #191a1a;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    transition: all 500ms ease;
  }

  .toggle-nav {
    left: auto;
    right: 30px;
    position: fixed;
    z-index: 9999999999999;
  }

  .toggle-nav .option {
    left: auto;
    right: 125%;
  }

  #carbonads {
    right: auto;
    left: 30px;
  }

  .menu-btn div {
    display: none;
  }

  .menu-btn span {
    display: none;
  }

  .responsive-menu {
    display: none;
  }

  @media only screen and (max-width: 1115px) {
    .mobile-nav {
      position: relative;
      display: inline-block;
      top: 8px;
      cursor: pointer;
    }

    .menu-btn .menu-btn {
      position: absolute;
      right: 100%;
      top: 63%;
      padding-right: 8px;
      margin-top: -0.5em;
      line-height: 1.2;
      font-size: 20px;
      font-weight: 200;
      vertical-align: middle;
      z-index: 99;
      cursor: pointer;
    }

    .menu-btn span {
      display: block;
      width: 25px;
      height: 3px;
      margin: 6px 0;
      background-color: #383a3a;
      z-index: 99;
    }

    .expand {
      display: block !important;
    }

    .mobile-link-container .page_item a {
      font-family: "Montserrat", sans-serif;
      color: #191a1a;
    }
  }

  @media only screen and (max-width: 391px) {
    #body-nav ul li a {
      font-size: 25px;
      height: auto;
      line-height: 45px;
    }

    #body-nav .mobile-link-container {
      top: 50%;
    }

    .toggle-nav {
      right: 14px;
    }
  }
}

// Scoped to <body> element
.show-nav #body-nav {
  background: rgba(234, 182, 11, 0.9);
  z-index: 100000;
  display: block;
}

.show-nav #body-nav ul li a {
  opacity: 1;
  display: inline-block;
  text-align: left;
}

.show-nav #body-nav ul li a:hover {
  color: #4f4e4e;
  text-decoration: underline;
}

.show-nav #body-nav ul .sub-item a {
  font-size: 24px;
  list-style-type: circle;
}
