@font-face {
  font-family: "OpenDyslexic";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/OpenDyslexic3-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenDyslexic";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/OpenDyslexic3-Bold.ttf") format("truetype");
}

#show-dyslexia-font {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "OpenDyslexic", sans-serif;
    font-weight: 700;
  }

  span,
  label,
  input,
  button,
  p,
  ul,
  a,
  li {
    font-family: "OpenDyslexic", sans-serif;
    font-size: 18px;
  }

  #btn-component,
  .btn {
    font-family: "OpenDyslexic", sans-serif;
  }
}
