.magnet-form {
  padding: 20px 20px;
  background-color: #dbdbdb;
  box-shadow: 0 10px 10px -12px #66666680;
  margin-bottom: 40px;

  h2 {
    margin-bottom: 0px;
  }

  .field-container {
    margin-bottom: 20px;
  }

  .field-name {
    margin-bottom: 0;
  }

  input {
    line-height: 20px;
    width: 90%;
    padding: 12px;
  }
}
