.user-area {
  .course-expired {
    margin: 5px 0px;
    padding: 10px 20px;
    background-color: #963939;
    color: #eeeeee;
    font-weight: bold;

    p {
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }
}

.user-area {
  .alert-text-container {
    float: right;
  }

  .lotu-container {
    .time-to-round {
      margin-top: 20px;
      width: 750px;

      @media only screen and (max-width: 730px) {
        width: 100%;
        text-align: center;
        .fa-clock-o {
          font-size: 20px;
          margin: 0 auto;
        }
      }

      // Icon.
      .fa-clock-o {
        font-size: 55px;
        margin-right: 22px;
        margin-top: 13px;
      }

      .alert-box {
        padding: 10px 20px;
        background-color: #3a8245;
        display: inline-block;
        color: #eeeeee;

        p {
          margin: 0;
          margin-bottom: 10px;
          font-size: 14px;
        }

        h2 {
          margin-top: 10px;
          font-size: 24px;
          display: inline-block;
          margin-bottom: 5px;
          margin-right: 35px;
        }

        .main-dash-alert p {
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
}
