a,
a:visited {
  color: #444;
  transition: 0.1s all;
  text-decoration: none;
}

.user-area {
  /*******************************/
  /* Default styles
	/*******************************/

  html {
    height: 100%;
  }

  body {
    background-color: #eeeeee;
    margin: 0;
    height: 100%;
    color: #444;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Bree Serif", serif;
    font-weight: 400;
  }

  h1 {
    font-size: 65px;
    border-bottom: 4px solid #eab60b;
    display: inline-block;
    margin-bottom: 15px;
    margin-top: 10px;

    @media only screen and (max-width: 530px) {
      font-size: 55px;
    }
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 25px;
  }

  .skilti-container {
    margin-bottom: 45px;

    h3 {
      margin-top: 0;
      margin-bottom: 14px;
    }

    p:first-of-type {
      margin-top: 0;
    }
  }

  p,
  ul,
  a {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    line-height: 160%;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    color: grey;
  }

  .lotu-container .caption {
    font-style: italic;
    font-size: 18px;
  }

  *::selection {
    /* Turns highlights into our brand colors */
    background: #eab60b;
  }

  *::-moz-selection {
    background: #eab60b;
  }

  textarea:focus,
  input:focus {
    outline: 0; /* Removes blue outline of forms*/
  }

  th {
    padding: 0;
    text-align: left;
    padding-right: 20px;
  }

  /*******************************/
  /* Get help text
	/* With "Þarftu aðstoð? Sendu tölvupóst á okukennari@urdarbrunnur.is"
	/*******************************/

  .get-help-text {
    width: 100%;
    margin-bottom: 0px;
    display: block;
  }

  .lotu-container .get-help-text p {
    font-size: 14px;
    display: block;
    margin-bottom: 15px;
  }

  /*******************************/
  /* Main content
	/*******************************/

  .main-content {
    position: relative;
    background-color: #eeeeee;
    z-index: 1;
  }

  .assignment {
    border: 2px solid #4d4d4d;
    padding: 0px 20px;
  }

  /*******************************/
  /* Lotur
	/*******************************/

  .lotu-container {
    width: 960px;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .lotu-content {
    p:first-child {
      margin-top: 0;
      top: 20px;
    }

    p {
      margin-top: 28px;
      margin-bottom: 28px;
    }
  }

  .main-content .step {
    margin-top: -5px;
  }

  /*******************************/
  /* Test
	/*******************************/

  .step-test-container li,
  .test-container li {
    list-style-type: none;
  }

  .test-container h2 {
    margin-top: 0px;
    padding-top: 110px;
  }

  .test-container li {
    width: 100%;
  }

  .answerRows {
    padding-left: 10px;
  }

  .questionRow-ul {
    padding-left: 0;
  }

  .questionRow-ul p {
    margin-bottom: 5px;
    font-weight: bold;
    margin-top: 15px;
  }

  input[type="checkbox"] {
    margin-right: 6px;
    width: 18px;
    height: 18px;
  }

  .checkbox-button-container label {
    cursor: pointer;
  }

  .correct {
    display: inline-block;
    background-color: rgba(0, 128, 0, 0.3);
    margin-bottom: 4px;
    padding: 2px 6px;
  }

  .wrong {
    display: inline-block;
    background-color: rgba(255, 0, 0, 0.3);
    margin-bottom: 4px;
    padding: 2px 6px;
  }

  /*******************************/
  /* Step Test
	/*******************************/

  .step-test-container .questionRow-ul {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .step-test-label {
    position: relative;
    margin-top: 0px;
    display: block;
  }

  .lotu-container .step-test-question {
    margin-top: 18px;
    margin-bottom: 15px;
  }

  .fa-check {
    color: #0c5b1a;
    margin-left: 5px;
  }

  .fa-times {
    color: #ed0000;
    margin-left: 5px;
  }

  .sign-in {
    margin: 0 auto;
    position: relative;
    width: 362.906px;
  }

  .sign-in form {
    margin-top: 150px;
  }

  .sign-in .field-container {
    margin-top: 22px;
    margin-bottom: 22px;
    position: relative;
    height: 40px;
  }

  .sign-in .field-name {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 24px;
  }

  .sign-in input {
    padding: 15px 20px 2px 4px;
    border: none;
    width: 342.906px;
    font-size: 22px;
    border-bottom: 2px solid #eab60b;
    background-color: #f9f9f9;
  }

  .sign-in input:valid {
    border-bottom: 2px solid #eab60b;
    background-color: white;
  }

  .sign-in input:focus {
    border-bottom: 2px solid #eab60b;
    background-color: white;
  }

  .sign-in input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
    -webkit-text-fill-color: #333;
  }

  .sign-in input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset; /*your box-shadow*/
    -webkit-text-fill-color: #333;
  }

  .sign-in .btn-submit {
    margin-top: 40px;
  }

  /*******************************/
  /* Buttons
	/*******************************/

  .btn:visited,
  .btn {
    padding: 12px;
    display: inline-block;
    border-radius: 2px;
    font-family: "Montserrat", sans-serif;
    border: 2px solid #eab60b;
    background-color: #eab60b;
    font-size: 20px;
    font-weight: 600;
    color: white;
    transition: 0.1s all;
    margin-top: 10px;
    text-decoration: none;

    &:focus,
    &:hover {
      border: 2px solid #cea210;
      background-color: #cea210;
      outline: 0;
      cursor: pointer;
      color: white;
    }

    &[disabled],
    &:disabled {
      cursor: not-allowed;
      border: 1px solid #ede0b6;
      background-color: #ede0b6;

      p {
        display: none;
      }

      &:hover {
        p {
          display: inline;
          position: absolute;
          top: 46px;
          left: 1px;
          font-size: 12px;
          color: #444444;
          background-color: #e4e4e4;
          opacity: 0.9;
          border-radius: 2px;
          padding: 5px 10px;
        }
      }
    }
  }

  .first-btn,
  #previous {
    margin-right: 20px;
  }

  .btn.gjafab-button {
    margin-left: 20px;
    background-color: inherit;
    color: #707070;
    border: none;
    border-bottom: 1px solid #707070;
    font-weight: 400;
    padding-bottom: 0px;
  }

  .giftcard-error {
    font-family: "Bree Serif", serif;
    font-size: 20px;
    font-weight: 400;
    display: inline-block;
    margin-left: 10px;
    padding: 3px 10px;
    background-color: #963939;
    color: white;
    border-radius: 2px;
  }

  #next {
    right: 0;
    position: relative;
  }

  #backToMain,
  #goToFinalTest,
  #forward {
    margin-left: 20px;
  }

  #backToMain,
  #forward {
    padding: 8px 8px;
  }

  .fa-arrow-right,
  .fa-arrow-left {
    font-size: 18px;
  }

  // Dashboard secondary button.
  .secondary-btn,
  .secondary-btn:visited {
    padding: 12px;
    border-radius: 2px;
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    border: 2px solid #eab60b;
    background-color: #eab60b;
    font-size: 20px;
    font-weight: 600;
    color: white;
    transition: 0.1s all;
    margin-top: 10px;
    text-decoration: none;
  }

  .secondary-btn:focus,
  .secondary-btn:hover {
    border: 2px solid #cea210;
    background-color: #cea210;
    outline: 0;
    cursor: pointer;
    color: white;
  }

  .positive-alert {
    margin: 10px 0px;
    padding: 15px 30px;
    background-color: #3a8245;
    color: #eeeeee;
    display: inline-block;
  }

  .positive-alert p {
    font-family: "Bree Serif", serif;
    font-size: 23px;
    font-weight: 400;
    display: inline-block;
    margin: 0;
  }

  .negative-alert {
    margin: 10px 0px;
    padding: 15px 30px;
    background-color: #963939;
    color: #eeeeee;
    display: inline-block;
  }

  .negative-alert p {
    font-family: "Bree Serif", serif;
    font-size: 23px;
    font-weight: 400;
    display: inline-block;
    margin: 0;
  }

  .restart-course {
    line-height: 160%;
    margin-left: 20px;
    position: relative;
  }

  .link {
    cursor: pointer;
    font-weight: bold;
  }

  /***********************************
	Media queries
	************************************/
  @media only screen and (max-width: 1030px) {
    .lotu-container {
      width: 95%;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    .lotu-content {
      iframe,
      img {
        width: 100% !important;
      }
    }

    .daysleft-alert-box {
      width: 100%;
    }
  }
}

.skraning-form-error {
  color: #990404;
  p {
    margin-top: 8px;
    font-size: 18px;
  }
}
