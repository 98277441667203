$input-width: 230px;

.settings-page {
  .text-setting {
    span {
      font-size: 18px;
      color: #444;
    }

    .label {
      font-size: 20px;
      margin: 0;
    }

    .text-input {
      font-size: 18px;
      width: $input-width;
      display: inline;

      input {
        width: $input-width;
        margin-right: 10px;
        color: #444;
      }
    }

    .edit-button {
      margin-left: 8px;
      padding: 4px 8px;
      font-size: 16px;
      border: none;
      background-color: none;
      text-decoration: underline;
    }

    .loading-text {
      display: inline-block;
      width: $input-width;
      margin-right: 10px;
    }
  }

  .error {
    margin-bottom: 0px;
    margin-top: 5px;
    color: #990404;
  }
}
