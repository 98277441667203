.site-wrap {
	table {
		width: 100%;
		border: 1px solid grey;
		margin-bottom: 30px;

		tr {
			font-size: 18px;
			&:nth-child(odd) {
				background-color: #e5e5e5;
			}
			&:nth-child(even) {
				background-color: #eeeeee;
			}

			&:last-child {
				border: 1px solid grey;
			}

			td {
				padding: 8px 5px;
			}
		}
	}
}
