.blog-overview {
  padding: 70px 0;

  h2 {
    font-size: 40px;
    margin-bottom: 40px;
    margin-top: 0px;
  }

  .blog-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 0;
  }

  .blog-card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;

    &:hover {
      transform: translateY(-5px);
    }

    .blog-image {
      width: 100%;
      padding-top: 56.25%; // 16:9 aspect ratio
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .blog-content {
      padding: 20px;
      text-align: left;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font-size: 32px;
        margin-top: 0px;
        margin-bottom: 8px;
        color: #333;
      }

      .subtitle {
        font-size: 18px;
        color: #666;
        margin: 0;
        line-height: 1.4;
      }

      .last-updated {
        font-size: 14px;
        font-style: italic;
        margin-top: 20px;
        color: #888;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .blog-overview {
    padding: 40px 20px;
  }
}

@media only screen and (max-width: 900px) {
  .blog-overview {
    .blog-grid {
      grid-template-columns: 1fr;
    }

    h2 {
      font-size: 32px;
    }
  }
}
