/*******************************/
/* Shared
/********************************/

.field-container {
  margin-top: 22px;
  margin-bottom: 22px;
  position: relative;
  min-height: 40px;
}

.field-name,
.field-name {
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 24px;
}

/*******************************/
/* Skráning
/********************************/

textarea:focus,
input:focus {
  outline: 0; /* Removes blue outline of forms*/
}

.checkbox-container .skilmala-link {
  text-decoration: underline;
  margin-left: 3px;
}

.skraning-section {
  max-width: 400px;
  margin: 0 auto;

  h1 {
    margin-bottom: 15px;
    margin-top: 60px;
  }

  .skraning-form-error {
    color: #990404;
    p {
      margin-top: 8px;
      font-size: 18px;
    }
  }
}

.skraning-section h2 {
  font-size: 40px;
  margin-top: 80px;
  margin-bottom: 20px;
}

.skraning-section h3 {
  font-size: 25px;
  margin-top: 35px;
}

.skraning-section p {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 2px;
  max-width: 600px;
}

.skraning-form input[type="password"],
.skraning-form input[type="text"] {
  padding: 15px 20px 2px 4px;
  border: none;
  width: 300px;
  font-size: 20px;
  border-bottom: 2px solid #eab60b;
  border-right: 1px solid #eab60b;
  border-left: 1px solid #ffe28e;
  border-top: 1px solid #ffe28e;
  background-color: #f9f9f9;
}

.skraning-form #submit {
  margin-top: 40px;
  border: none;
  border-radius: 2px;
  font-size: 20px;
  padding: 15px 30px;
  background-color: #eab60b;
  color: white;
  font-weight: 800;
}

.skraning-form #submit:focus,
.skraning-form #submit:hover {
  outline: none;
  background-color: #efb907;
}

.skraning-form {
  margin-top: 20px;
}

.last-field-container {
  margin-bottom: 30px;
}

.skraning-form .verd p {
  font-size: 18px;
  margin: 20px 0px;
}

.checkbox-container {
  margin-bottom: 10px;

  .label-text {
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
  }
}

.checkbox-container label {
  font-size: 12px;
  top: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.skraning-form input[type="checkbox"] {
  height: 18px;
  width: 18px;
  background-color: red;
  cursor: pointer;
  margin-right: 5px;
  position: relative;
  top: 4px;
}

.checkbox-container input:focus[type="checkbox"] {
  margin-bottom: 1px solid #333;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

/*******************************/
/* Sign in
	/********************************/

.sign-in h1 {
  font-size: 65px;
  border-bottom: 4px solid #eab60b;
  display: inline-block;
  margin-bottom: 15px;
  margin-top: 10px;
}

.sign-in {
  margin: 0 auto;
  position: relative;
  width: 362.906px;
}

.sign-in .sign-in-container {
  padding-top: 150px;
  padding-bottom: 150px;
}

.sign-in input {
  padding: 15px 20px 2px 4px;
  border: none;
  width: 342.906px;
  font-size: 22px;
  border-bottom: 2px solid #eab60b;
  border-right: 1px solid #eab60b;
  border-left: 1px solid #ffe28e;
  border-top: 1px solid #ffe28e;
  background-color: #f9f9f9;
}

.sign-in input:valid {
  border-bottom: 2px solid #eab60b;
  background-color: white;
}

.sign-in input:focus {
  border-bottom: 2px solid #eab60b;
  background-color: white;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset; /*your box-shadow*/
  -webkit-text-fill-color: #333;
}

.sign-in .btn-submit {
  margin-top: 40px;
}

.sign-in .btn {
  padding: 8px;
  border-radius: 2px;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #eab60b;
  background-color: #eab60b;
  font-size: 20px;
  font-weight: 600;
  float: left;
  color: white;
  transition: 0.1s all;
  margin-top: 20px;
  margin-bottom: 30px;
}

.sign-in .btn:focus,
.sign-in .btn:hover {
  border: 2px solid #cea210;
  background-color: #cea210;
  outline: 0;
  cursor: pointer;
}

.gleymt-lykilord-link {
  padding: 10px 2px;
  font-family: "Montserrat", sans-serif;
  border-bottom: 2px solid #cea210;
  font-weight: 400;
  display: inline-block;
  margin-left: 30px;
  margin-top: 24px;
}

.gleymt-lykilord-link:hover {
  color: grey;
}

@media only screen and (max-width: 391px) {
  .field-container .field-name {
    font-size: 18px;
  }

  .sign-in input[type="number"],
  .sign-in input[type="text"],
  .sign-in input[type="password"],
  .skraning-form input[type="password"],
  .skraning-form input[type="text"] {
    width: 90%;
  }
}

.help-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #666;
  color: white;
  font-size: 12px;
  margin-left: 6px;
  cursor: help;
  font-weight: bold;
  transition: all 0.2s ease;

  &:hover {
    background-color: #444;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
}
