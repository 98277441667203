:root {
  --background: 0 0% 100%;
  --foreground: 222.2 47.4% 11.2%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;
  --ring: 215 20.2% 65.1%;
  --radius: .5rem;
}

* {
  border-color: hsl(var(--border));
}

body {
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
  font-feature-settings: "rlig" 1, "calt" 1;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.pointer-events-none {
  pointer-events: none;
}

.relative {
  position: relative;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-5 {
  width: 1.25rem;
}

.min-w-full {
  min-width: 100%;
}

.shrink-0 {
  flex-shrink: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.overflow-x-auto {
  overflow-x: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: var(--radius);
}

.rounded-md {
  border-radius: calc(var(--radius)  - 2px);
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-transparent {
  border-color: #0000;
}

.bg-background {
  background-color: hsl(var(--background));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-5 {
  line-height: 1.25rem;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-input {
  color: hsl(var(--input));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

@keyframes enter {
  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

.duration-200 {
  animation-duration: .2s;
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-ring:focus-visible {
  --tw-ring-color: hsl(var(--ring));
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:ring-offset-background:focus-visible {
  --tw-ring-offset-color: hsl(var(--background));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.data-\[state\=checked\]\:translate-x-5[data-state="checked"] {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\=unchecked\]\:translate-x-0[data-state="unchecked"] {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\=checked\]\:bg-primary[data-state="checked"] {
  background-color: hsl(var(--primary));
}

.data-\[state\=unchecked\]\:bg-input[data-state="unchecked"] {
  background-color: hsl(var(--input));
}

@media (width >= 768px) {
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

:is(button::-moz-focus-inner, input::-moz-focus-inner) {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

:is(input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button) {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

:is(input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration) {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@font-face {
  font-family: OpenDyslexic;
  font-style: normal;
  font-weight: 400;
  src: url("OpenDyslexic3-Regular.ttf") format("truetype");
}

@font-face {
  font-family: OpenDyslexic;
  font-style: normal;
  font-weight: 700;
  src: url("OpenDyslexic3-Bold.ttf") format("truetype");
}

#show-dyslexia-font h1, #show-dyslexia-font h2, #show-dyslexia-font h3, #show-dyslexia-font h4, #show-dyslexia-font h5 {
  font-family: OpenDyslexic, sans-serif;
  font-weight: 700;
}

#show-dyslexia-font span, #show-dyslexia-font label, #show-dyslexia-font input, #show-dyslexia-font button, #show-dyslexia-font p, #show-dyslexia-font ul, #show-dyslexia-font a, #show-dyslexia-font li {
  font-family: OpenDyslexic, sans-serif;
  font-size: 18px;
}

#show-dyslexia-font #btn-component, #show-dyslexia-font .btn {
  font-family: OpenDyslexic, sans-serif;
}

.mobile-menu .assistive-text {
  width: 1px;
  height: 1px;
  position: absolute;
  top: -10px;
  overflow: hidden;
}

.mobile-menu #body-nav {
  text-align: left;
  -webkit-touch-callout: none;
  user-select: none;
  -khtml-user-select: none;
  text-align: center;
  z-index: 0;
  background: none;
  width: 100%;
  height: 100%;
  transition: background .5s;
  display: none;
  position: fixed;
  top: 0;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.mobile-menu .hide-nav #body-nav {
  z-index: -400;
  background: none;
  width: 100%;
  height: 100%;
}

.mobile-menu #body-nav ul {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateX(-50%)translateY(-50%);
  width: 100%;
  padding-left: 0;
  list-style: none;
  position: absolute;
  top: 40%;
  left: 0%;
  transform: translateY(-50%);
}

.mobile-menu #body-nav ul li a {
  opacity: 0;
  color: #191a1a;
  border: none;
  height: 50px;
  font-family: Montserrat, sans-serif;
  font-size: 35px;
  font-weight: 800;
  line-height: 100px;
  transition: all .5s;
  display: none;
  text-decoration: none !important;
}

.mobile-menu .toggle-nav {
  z-index: 2147483647;
  position: fixed;
  left: auto;
  right: 30px;
}

.mobile-menu .toggle-nav .option {
  left: auto;
  right: 125%;
}

.mobile-menu #carbonads {
  left: 30px;
  right: auto;
}

.mobile-menu .menu-btn div, .mobile-menu .menu-btn span, .mobile-menu .responsive-menu {
  display: none;
}

@media only screen and (width <= 1115px) {
  .mobile-menu .mobile-nav {
    cursor: pointer;
    display: inline-block;
    position: relative;
    top: 8px;
  }

  .mobile-menu .menu-btn .menu-btn {
    vertical-align: middle;
    z-index: 99;
    cursor: pointer;
    margin-top: -.5em;
    padding-right: 8px;
    font-size: 20px;
    font-weight: 200;
    line-height: 1.2;
    position: absolute;
    top: 63%;
    right: 100%;
  }

  .mobile-menu .menu-btn span {
    z-index: 99;
    background-color: #383a3a;
    width: 25px;
    height: 3px;
    margin: 6px 0;
    display: block;
  }

  .mobile-menu .expand {
    display: block !important;
  }

  .mobile-menu .mobile-link-container .page_item a {
    color: #191a1a;
    font-family: Montserrat, sans-serif;
  }
}

@media only screen and (width <= 391px) {
  .mobile-menu #body-nav ul li a {
    height: auto;
    font-size: 25px;
    line-height: 45px;
  }

  .mobile-menu #body-nav .mobile-link-container {
    top: 50%;
  }

  .mobile-menu .toggle-nav {
    right: 14px;
  }
}

.show-nav #body-nav {
  z-index: 100000;
  background: #eab60be6;
  display: block;
}

.show-nav #body-nav ul li a {
  opacity: 1;
  text-align: left;
  display: inline-block;
}

.show-nav #body-nav ul li a:hover {
  color: #4f4e4e;
  text-decoration: underline;
}

.show-nav #body-nav ul .sub-item a {
  font-size: 24px;
  list-style-type: circle;
}

html, body {
  color: #444;
  background-color: #eee;
  height: 100%;
}

.site-wrap {
  width: 100%;
  height: 100%;
}

.site-wrap h1, .site-wrap h2, .site-wrap h3, .site-wrap h4, .site-wrap h5 {
  font-family: Bree Serif, serif;
  font-weight: 400;
}

.site-wrap p, .site-wrap ul, .site-wrap a, .site-wrap li {
  font-family: Open Sans, sans-serif;
}

.site-wrap a {
  text-decoration: underline;
}

.site-wrap h1 {
  border-bottom: 4px solid #eab60b;
  font-size: 65px;
  display: inline-block;
}

.site-wrap ul {
  margin-bottom: 15px;
  list-style-type: none;
}

.site-wrap li {
  font-size: 20px;
}

.site-wrap p {
  font-size: 1.625rem;
}

.site-wrap header, .site-wrap .footer-row, .site-wrap .main-body {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.site-wrap .lotu-container .caption {
  font-style: italic;
}

.site-wrap ::selection {
  background: #eab60b;
}

.site-wrap ::selection {
  background: #eab60b;
}

.site-wrap .feedback-message {
  color: #fff;
  padding: 10px 20px;
  font-weight: bold;
  display: inline-block;
  font-size: 16px !important;
}

.site-wrap .error-message {
  background-color: #d80000;
}

.site-wrap .success-message {
  background-color: #006300;
}

.site-wrap .fa-clock-o, .site-wrap .fa-car, .site-wrap .fa-phone-square, .site-wrap .main-body .fa-picture-o, .site-wrap .main-body .fa-money, .site-wrap .main-body .fa-check {
  color: #eab60b;
  margin-right: 7px;
  font-size: 2rem;
  position: relative;
  top: 3px;
}

.site-wrap .checkmark-container .checkmark-text {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 20px;
  display: inline-block;
}

.site-wrap .logo-container {
  display: inline-block;
}

.site-wrap .logo-container .assistive-text {
  width: 1px;
  height: 1px;
  position: absolute;
  top: -10px;
  overflow: hidden;
}

.site-wrap .logo-container #logo {
  width: 250px;
  height: 49px;
  margin-top: 16px;
  display: inline-block;
}

.site-wrap .navbar ul li {
  float: right;
  margin-left: 14px;
  font-size: 18px;
  position: relative;
  bottom: 8px;
}

.site-wrap .navbar ul li:last-child {
  margin-left: 0;
}

.site-wrap .navbar ul li a {
  color: #7a7a7a;
  padding-bottom: 2px;
  font-weight: 400;
  text-decoration: none;
}

.site-wrap .navbar ul li .primary-cta {
  color: #fff;
  background-color: #eab60b;
  border-radius: 2px;
  padding: 5px 10px;
  font-family: Montserrat, sans-serif;
  font-weight: bolder;
}

.site-wrap .navbar ul li .primary-cta:hover {
  color: #fff;
  background-color: #cea210;
  border-bottom: 0;
}

.site-wrap .navbar ul li .active, .site-wrap .navbar ul li a:focus, .site-wrap .navbar ul li a:active, .site-wrap .navbar ul li a:hover {
  color: #444;
  border-bottom: 2px solid #eab60b;
  margin-bottom: -2px;
}

.site-wrap .navbar {
  float: right;
  margin: 0;
  padding: 0;
  position: relative;
  top: 23px;
}

.site-wrap .navbar > li {
  float: left;
  margin: 0 20px;
  padding: 16px 0;
  font-size: 18px;
  font-weight: bolder;
  list-style: none;
  display: inline-block;
}

.site-wrap .first-section {
  min-height: 700px;
}

.site-wrap .front-page-first-text {
  width: 58%;
  display: inline-block;
}

.site-wrap .front-page-first-text p {
  margin-top: 0;
}

.site-wrap .front-page-image {
  float: left;
  border-radius: 370px;
  width: 38%;
  margin-top: 80px;
  margin-right: 4%;
}

.site-wrap .second-section {
  background-color: #e5e5e5;
  min-height: 560px;
}

.site-wrap .fourth-section {
  background-color: #e5e5e5;
}

.site-wrap .third-section {
  min-height: 370px;
  padding-bottom: 60px;
}

.site-wrap .third-section .initial-text {
  margin-top: 80px;
}

.site-wrap .third-section .initial-text h2 {
  margin-bottom: 10px;
  font-size: 40px;
}

.site-wrap .third-section .initial-text #btn-component {
  margin-top: 10px;
}

.site-wrap .third-section-text-container {
  text-align: center;
  max-width: 500px;
  margin: 140px auto;
  padding-top: 40px;
  padding-bottom: 30px;
}

.site-wrap .third-section-text-container p {
  margin-top: 15px;
  font-size: 20px;
}

.site-wrap .third-section-text-container .button-container {
  margin-top: 10px;
  display: inline-block;
}

.site-wrap .third-section img {
  width: 49%;
  margin-top: 100px;
  margin-right: 1%;
}

.site-wrap .third-section img:nth-child(2) {
  margin-left: 1%;
  margin-right: 0;
}

.site-wrap .third-section .button-container {
  margin-top: 10px;
  display: inline-block;
}

.site-wrap .third-section p {
  margin-top: 15px;
  font-size: 20px;
}

.site-wrap .second-section .sales-main-text {
  margin-bottom: 65px;
  padding-top: 85px;
  font-size: 26px;
  line-height: 150%;
}

.site-wrap .second-section h2 {
  border-bottom: 4px solid #444;
  padding-top: 100px;
  font-size: 50px;
  display: inline-block;
}

.site-wrap .second-section .threebytwo {
  width: 31.5%;
  height: 160px;
  margin-right: 1.5%;
  display: inline-block;
  position: relative;
}

.site-wrap .threebytwo .fa {
  color: #eab60b;
  margin-right: 8px;
  font-size: 2rem;
}

.site-wrap .second-section .threebytwo p {
  margin-top: 5px;
  position: absolute;
}

.site-wrap .threebytwo h2 {
  border-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  font-family: Bree Serif, serif;
  font-size: 30px;
  font-weight: 400;
  display: inline-block;
}

.site-wrap .second-section p {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
}

.site-wrap .front-page h1 {
  border-bottom: initial;
  margin-top: 120px;
  margin-bottom: 0;
  font-size: 65px;
}

.site-wrap .sign-in h1 {
  margin-top: 210px;
  margin-bottom: 15px;
  font-size: 65px;
}

.site-wrap .main-body p {
  margin-bottom: 15px;
}

.site-wrap .button-container {
  margin-top: 20px;
}

.site-wrap .main-button:visited, .site-wrap .main-button {
  float: left;
  color: #fff;
  background-color: #eab60b;
  border: 2px solid #eab60b;
  border-radius: 3px;
  padding: 16px;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
}

.site-wrap .secondary-button {
  border-bottom: 2px solid #cea210;
  margin-top: 6px;
  margin-left: 30px;
  padding: 16px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
}

.site-wrap .main-button:hover, .site-wrap .main-button:focus {
  background-color: #cea210;
  border: 2px solid #cea210;
}

.site-wrap .secondary-button:hover, .site-wrap .secondary-button:focus {
  border-bottom: 2px solid #eab60b;
}

.site-wrap .default-page h1 {
  margin-bottom: 0;
}

.site-wrap .default-page h2 {
  margin-bottom: 20px;
  font-size: 40px;
}

.site-wrap .default-page p {
  font-size: 20px;
}

.site-wrap .default-page .button-container {
  margin-top: 120px;
  display: inline-block;
}

.site-wrap .default-page .spurningar-p {
  margin-top: 10px;
  margin-bottom: 120px;
  font-size: 18px;
}

.site-wrap .default-page .button-container .main-button {
  font-size: 20px;
}

.site-wrap .default-text-container {
  width: 80%;
}

.site-wrap .about-us p, .site-wrap .about-us li {
  font-size: 22px;
  line-height: 160%;
}

.site-wrap .about-us ul {
  margin: 25px 0;
}

.site-wrap .starfsmadur {
  width: 33%;
  margin-right: 2%;
  display: inline-block;
}

.site-wrap .starfsmadur h3 {
  margin-bottom: 0;
  font-size: 24px;
}

.site-wrap .starfsmadur p {
  font-size: 18px1;
  margin-top: 0;
}

.site-wrap .starfsmadur img {
  width: 100%;
}

.site-wrap footer {
  background-color: #e5e5e5;
  margin-top: 50px;
  line-height: 160%;
}

.site-wrap footer p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.site-wrap footer a {
  text-decoration: underline;
}

.site-wrap footer h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.site-wrap .footer-row {
  min-height: 200px;
  padding-top: 40px;
}

.site-wrap .footer-item {
  float: left;
  width: 24%;
  height: 200px;
  margin-right: 1%;
}

.site-wrap .footer-item a {
  margin-top: 7px;
  display: block;
}

.site-wrap .bottom-footer {
  background-color: #e5e5e5;
  height: 55px;
}

.site-wrap .bottom-footer p {
  text-align: center;
  margin-bottom: 15px;
  padding-top: 15px;
  font-size: 14px;
}

.site-wrap .cc-logos {
  height: 28px;
}

.site-wrap .forgot-password-page .field-name {
  margin-top: 20px;
  margin-bottom: 2px;
  font-size: 20px;
}

.site-wrap .reset-page .btn, .site-wrap .forgot-password-page .btn {
  color: #fff;
  background-color: #eab60b;
  border: 2px solid #eab60b;
  border-radius: 2px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
}

.site-wrap .reset-page .btn:focus, .site-wrap .reset-page .btn:hover, .site-wrap .forgot-password-page .btn:focus, .site-wrap .forgot-password-page .btn:hover {
  cursor: pointer;
  background-color: #cea210;
  border: 2px solid #cea210;
  outline: 0;
}

.site-wrap .reset-page input, .site-wrap .forgot-password-page input {
  background-color: #f9f9f9;
  border: none;
  border-bottom: 2px solid #eab60b;
  width: 200px;
  padding: 15px 20px 2px 4px;
  font-size: 22px;
}

.site-wrap .reset-page input:valid, .site-wrap .forgot-password-page input:valid, .site-wrap .reset-page input:focus, .site-wrap .forgot-password-page input:focus {
  background-color: #fff;
  border-bottom: 2px solid #eab60b;
}

.site-wrap .reset-page .field-name {
  margin-top: 12px;
  margin-bottom: 5px;
}

.site-wrap .reset-form-error-short .fa, .site-wrap .reset-form-error .fa {
  margin-right: 7px;
}

.site-wrap .reset-form-error div {
  color: #fff;
  background-color: #d82424;
  padding: 10px 15px;
  font-weight: bold;
  position: absolute;
  bottom: 3px;
  left: 230px;
}

.site-wrap .reset-form-error-short div {
  color: #fff;
  background-color: #d82424;
  padding: 10px 15px;
  font-weight: bold;
  position: absolute;
  bottom: 93px;
  left: 230px;
}

.site-wrap .salespage-hero-section {
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
}

.site-wrap .salespage-hero-section .first-box {
  min-width: 55%;
}

.site-wrap .salespage-hero-section .second-box {
  flex: 1;
  min-width: 40%;
  margin-top: 20px;
  margin-left: 20px;
}

@media only screen and (width <= 770px) {
  .site-wrap .salespage-hero-section .second-box {
    height: 180px;
    margin-left: 0;
  }
}

.site-wrap .salespage-hero-section .second-box .main-image {
  object-fit: cover;
  object-position: left;
  width: 100%;
  height: 100%;
  max-height: 380px;
}

.site-wrap .salespage-hero-section .points .fa {
  color: #cea210;
}

.site-wrap .salespage-hero-section .points p {
  margin-top: 0;
  margin-bottom: 6px;
}

@media only screen and (width <= 770px) {
  .site-wrap .salespage-hero-section {
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

@media only screen and (width <= 770px) {
  .site-wrap .social-proof {
    margin: 30px 0;
    padding: 30px 1rem;
  }

  .site-wrap .social-proof h2 {
    margin-bottom: 30px;
  }

  .site-wrap .social-proof .testimonials {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .site-wrap .social-proof .testimonials .testimonial {
    width: 100%;
    max-width: 100%;
  }
}

.site-wrap .social-proof {
  text-align: center;
  background-color: #e5e5e5;
  border-radius: 8px;
  margin: 40px 0;
  padding: 40px 1rem 60px;
}

.site-wrap .social-proof h2 {
  margin-bottom: 40px;
  font-size: 32px;
}

.site-wrap .social-proof .testimonials {
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.site-wrap .social-proof .testimonials .testimonial {
  flex: 1;
  min-width: 300px;
  max-width: 450px;
}

.site-wrap .social-proof .testimonials .testimonial .testimonial-content {
  background: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 8px #0000001a;
}

.site-wrap .social-proof .testimonials .testimonial .testimonial-content p {
  margin-bottom: 20px;
  font-size: 22px;
  font-style: italic;
  line-height: 1.6;
}

.site-wrap .social-proof .testimonials .testimonial .testimonial-content .testimonial-author {
  font-size: 21px;
  font-weight: 600;
}

@media only screen and (width <= 770px) {
  .site-wrap .social-proof {
    margin: 30px 0;
    padding: 30px 1rem;
  }

  .site-wrap .social-proof h2 {
    margin-bottom: 30px;
  }

  .site-wrap .social-proof .testimonials {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .site-wrap .social-proof .testimonials .testimonial {
    width: 100%;
    max-width: 100%;
  }
}

.site-wrap .sales-page p {
  font-size: 22px;
}

.site-wrap .sales-page ul li {
  font-size: 21px;
  list-style-type: square;
}

.site-wrap .sales-page h2 {
  margin-top: 40px;
}

.site-wrap .steering-wheel {
  float: left;
  border-radius: 500px;
  width: 400px;
  margin-bottom: 40px;
  margin-right: 40px;
}

.site-wrap .img-and-info {
  width: 100%;
  margin-top: 40px;
}

.site-wrap .screenshots {
  width: 48%;
  margin-top: 40px;
  margin-bottom: 20px;
}

.site-wrap .first-screenshot {
  margin-right: 4%;
}

@media only screen and (width <= 770px) {
  .site-wrap .steering-wheel {
    display: none;
  }

  .site-wrap .img-and-info {
    margin-bottom: 20px;
  }
}

.site-wrap .blog-overview {
  padding: 70px 0;
}

.site-wrap .blog-overview h2 {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 40px;
}

.site-wrap .blog-overview .blog-grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 0;
  display: grid;
}

.site-wrap .blog-overview .blog-card {
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  text-decoration: none;
  transition: transform .2s ease-in-out;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2px 8px #0000001a;
}

.site-wrap .blog-overview .blog-card:hover {
  transform: translateY(-5px);
}

.site-wrap .blog-overview .blog-card .blog-image {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.site-wrap .blog-overview .blog-card .blog-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.site-wrap .blog-overview .blog-card .blog-content {
  text-align: left;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 20px;
  display: flex;
}

.site-wrap .blog-overview .blog-card .blog-content h3 {
  color: #333;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 32px;
}

.site-wrap .blog-overview .blog-card .blog-content .subtitle {
  color: #666;
  margin: 0;
  font-size: 18px;
  line-height: 1.4;
}

.site-wrap .blog-overview .blog-card .blog-content .last-updated {
  color: #888;
  margin-top: 20px;
  font-size: 14px;
  font-style: italic;
}

@media only screen and (width <= 1200px) {
  .site-wrap .blog-overview {
    padding: 40px 20px;
  }
}

@media only screen and (width <= 900px) {
  .site-wrap .blog-overview .blog-grid {
    grid-template-columns: 1fr;
  }

  .site-wrap .blog-overview h2 {
    font-size: 32px;
  }
}

.site-wrap .faq-section {
  background-color: #e5e5e5;
  border-radius: 8px;
  margin: 40px 0;
  padding: 40px 0;
}

.site-wrap .faq-section h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 32px;
}

.site-wrap .faq-section .faq-grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
}

.site-wrap .faq-section .faq-item {
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  transition: all .3s;
  overflow: hidden;
  box-shadow: 0 2px 8px #0000001a;
}

.site-wrap .faq-section .faq-item:hover {
  background: #f8f8f8;
}

.site-wrap .faq-section .faq-item.open {
  background: #fff;
}

.site-wrap .faq-section .faq-item.open .faq-icon {
  color: #fff;
  background-color: #eab60b;
}

.site-wrap .faq-section .faq-question {
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  display: flex;
}

.site-wrap .faq-section .faq-question h3 {
  color: #444;
  margin: 0;
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.site-wrap .faq-section .faq-question .faq-icon {
  background-color: #0000001a;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 18px;
  transition: all .3s;
  display: flex;
}

.site-wrap .faq-section .faq-answer {
  background: #f8f8f8;
  max-height: 0;
  transition: max-height .3s ease-out;
  overflow: hidden;
}

.site-wrap .faq-section .faq-answer p {
  color: #444;
  margin: 0;
  padding: 20px;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

.site-wrap .faq-section .faq-item.open .faq-answer {
  max-height: 300px;
}

@media only screen and (width <= 900px) {
  .site-wrap .faq-section {
    margin: 30px 0;
    padding: 30px 20px;
  }

  .site-wrap .faq-section .faq-grid {
    grid-template-columns: 1fr;
  }

  .site-wrap .faq-section h2 {
    font-size: 32px;
  }
}

.site-wrap .field-container {
  min-height: 40px;
  margin-top: 22px;
  margin-bottom: 22px;
  position: relative;
}

.site-wrap .field-name, .site-wrap .field-name {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 24px;
}

.site-wrap textarea:focus, .site-wrap input:focus {
  outline: 0;
}

.site-wrap .checkbox-container .skilmala-link {
  margin-left: 3px;
  text-decoration: underline;
}

.site-wrap .skraning-section {
  max-width: 400px;
  margin: 0 auto;
}

.site-wrap .skraning-section h1 {
  margin-top: 60px;
  margin-bottom: 15px;
}

.site-wrap .skraning-section .skraning-form-error {
  color: #990404;
}

.site-wrap .skraning-section .skraning-form-error p {
  margin-top: 8px;
  font-size: 18px;
}

.site-wrap .skraning-section h2 {
  margin-top: 80px;
  margin-bottom: 20px;
  font-size: 40px;
}

.site-wrap .skraning-section h3 {
  margin-top: 35px;
  font-size: 25px;
}

.site-wrap .skraning-section p {
  max-width: 600px;
  margin-top: 10px;
  margin-bottom: 2px;
  font-size: 20px;
}

.site-wrap .skraning-form input[type="password"], .site-wrap .skraning-form input[type="text"] {
  background-color: #f9f9f9;
  border-top: 1px solid #ffe28e;
  border-bottom: 2px solid #eab60b;
  border-left: 1px solid #ffe28e;
  border-right: 1px solid #eab60b;
  width: 300px;
  padding: 15px 20px 2px 4px;
  font-size: 20px;
}

.site-wrap .skraning-form #submit {
  color: #fff;
  background-color: #eab60b;
  border: none;
  border-radius: 2px;
  margin-top: 40px;
  padding: 15px 30px;
  font-size: 20px;
  font-weight: 800;
}

.site-wrap .skraning-form #submit:focus, .site-wrap .skraning-form #submit:hover {
  background-color: #efb907;
  outline: none;
}

.site-wrap .skraning-form {
  margin-top: 20px;
}

.site-wrap .last-field-container {
  margin-bottom: 30px;
}

.site-wrap .skraning-form .verd p {
  margin: 20px 0;
  font-size: 18px;
}

.site-wrap .checkbox-container {
  margin-bottom: 10px;
}

.site-wrap .checkbox-container .label-text {
  align-items: center;
  margin-top: 10px;
  display: inline-flex;
}

.site-wrap .checkbox-container label {
  cursor: pointer;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  display: flex;
  top: 0;
}

.site-wrap .skraning-form input[type="checkbox"] {
  cursor: pointer;
  background-color: red;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  position: relative;
  top: 4px;
}

.site-wrap .checkbox-container input:focus[type="checkbox"] {
  margin-bottom: 1px solid #333;
}

.site-wrap .sign-in h1 {
  border-bottom: 4px solid #eab60b;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 65px;
  display: inline-block;
}

.site-wrap .sign-in {
  width: 362.906px;
  margin: 0 auto;
  position: relative;
}

.site-wrap .sign-in .sign-in-container {
  padding-top: 150px;
  padding-bottom: 150px;
}

.site-wrap .sign-in input {
  background-color: #f9f9f9;
  border-top: 1px solid #ffe28e;
  border-bottom: 2px solid #eab60b;
  border-left: 1px solid #ffe28e;
  border-right: 1px solid #eab60b;
  width: 342.906px;
  padding: 15px 20px 2px 4px;
  font-size: 22px;
}

.site-wrap .sign-in input:valid, .site-wrap .sign-in input:focus {
  background-color: #fff;
  border-bottom: 2px solid #eab60b;
}

.site-wrap input:-webkit-autofill {
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: inset 0 0 0 50px #fff;
}

.site-wrap input:-webkit-autofill:focus {
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: inset 0 0 0 50px #fff;
}

.site-wrap .sign-in .btn-submit {
  margin-top: 40px;
}

.site-wrap .sign-in .btn {
  float: left;
  color: #fff;
  background-color: #eab60b;
  border: 2px solid #eab60b;
  border-radius: 2px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  transition: all .1s;
}

.site-wrap .sign-in .btn:focus, .site-wrap .sign-in .btn:hover {
  cursor: pointer;
  background-color: #cea210;
  border: 2px solid #cea210;
  outline: 0;
}

.site-wrap .gleymt-lykilord-link {
  border-bottom: 2px solid #cea210;
  margin-top: 24px;
  margin-left: 30px;
  padding: 10px 2px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  display: inline-block;
}

.site-wrap .gleymt-lykilord-link:hover {
  color: gray;
}

@media only screen and (width <= 391px) {
  .site-wrap .field-container .field-name {
    font-size: 18px;
  }

  .site-wrap .sign-in input[type="number"], .site-wrap .sign-in input[type="text"], .site-wrap .sign-in input[type="password"], .site-wrap .skraning-form input[type="password"], .site-wrap .skraning-form input[type="text"] {
    width: 90%;
  }
}

.site-wrap .help-icon {
  color: #fff;
  cursor: help;
  background-color: #666;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-left: 6px;
  font-size: 12px;
  font-weight: bold;
  transition: all .2s;
  display: inline-flex;
}

.site-wrap .help-icon:hover {
  background-color: #444;
  box-shadow: 0 1px 3px #0003;
}

.site-wrap .blog-text {
  max-width: 800px;
  margin: 0 auto;
}

.site-wrap .blog-text img {
  max-width: 100%;
}

.site-wrap .blog-text .image-caption {
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 14px;
  font-style: italic;
  display: block;
}

.site-wrap .blog-text a {
  text-decoration: underline;
}

.site-wrap .blog-text p {
  font-size: 20px;
}

.site-wrap .blog-text ul li {
  list-style-type: disc;
}

.site-wrap .blog-text h1 {
  border-bottom: none;
  margin-bottom: 10px;
}

.site-wrap .blog-text h2 {
  margin-top: 80px;
  margin-bottom: 20px;
  font-size: 40px;
}

.site-wrap .blog-text h3 {
  margin-top: 60px;
  margin-bottom: 10px;
  font-size: 30px;
}

.site-wrap .blog-text .sub-title {
  margin-bottom: 50px;
  font-size: 20px;
  font-style: italic;
  display: block;
}

.site-wrap .blog-text .last-updated {
  font-size: 14px;
}

.site-wrap .select-search-container {
  --select-search-background: #f9f9f9;
  --select-search-border: #dce0e8;
  --select-search-selected: #eab60b;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: "Inter", sans-serif;
  font-size: 18px;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  box-sizing: border-box;
  max-width: 325px;
  position: relative;
}

@supports (font-variation-settings: normal) {
  .site-wrap .select-search-container {
    --select-search-font: "Inter var", sans-serif;
  }
}

.site-wrap .select-search-container *, .site-wrap .select-search-container :after, .site-wrap .select-search-container :before {
  box-sizing: inherit;
}

.site-wrap .select-search-input {
  z-index: 1;
  background: var(--select-search-background);
  color: var(--select-search-text);
  font-family: var(--select-search-font);
  text-align: left;
  text-overflow: ellipsis;
  letter-spacing: .01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  border-top: 1px solid #ffe28e;
  border-bottom: 2px solid #eab60b;
  border-left: 1px solid #ffe28e;
  border-right: 1px solid #eab60b;
  border-radius: 3px;
  outline: none;
  width: 100%;
  height: 48px;
  padding: 0 40px 0 16px;
  font-size: 18px;
  line-height: 48px;
  display: block;
  position: relative;
}

.site-wrap .select-search-is-multiple .select-search-input {
  border-radius: 3px 3px 0 0;
  margin-bottom: -2px;
}

:is(.site-wrap .select-search-input::-webkit-search-decoration, .site-wrap .select-search-input::-webkit-search-cancel-button, .site-wrap .select-search-input::-webkit-search-results-button, .site-wrap .select-search-input::-webkit-search-results-decoration) {
  -webkit-appearance: none;
}

.site-wrap .select-search-input[readonly] {
  cursor: pointer;
}

.site-wrap .select-search-is-disabled .select-search-input {
  cursor: not-allowed;
}

.site-wrap .select-search-container:not(.select-search-is-disabled).select-search-has-focus .select-search-input, .site-wrap .select-search-container:not(.select-search-is-disabled) .select-search-input:hover {
  border-color: var(--select-search-selected);
}

.site-wrap .select-search-select {
  background: var(--select-search-background);
  border: 2px solid var(--select-search-border);
  max-height: 360px;
  overflow: auto;
  box-shadow: 0 .0625rem .125rem #00000026;
}

.site-wrap .select-search-container:not(.select-search-is-multiple) .select-search-select {
  z-index: 2;
  border-radius: 3px;
  display: none;
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
}

.site-wrap .select-search-container:not(.select-search-is-multiple).select-search-has-focus .select-search-select {
  display: block;
}

.site-wrap .select-search-has-focus .select-search-select {
  border-color: var(--select-search-selected);
}

.site-wrap .select-search-options {
  list-style: none;
}

.site-wrap .select-search-option, .site-wrap .select-search-not-found {
  background: var(--select-search-background);
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  text-align: left;
  letter-spacing: .01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  border: none;
  outline: none;
  width: 100%;
  height: 42px;
  padding: 0 16px;
  font-size: 16px;
  display: block;
}

.site-wrap .select-search-option:disabled {
  opacity: .5;
  cursor: not-allowed;
  background: none !important;
}

.site-wrap .select-search-is-highlighted, .site-wrap .select-search-option:not(.select-search-is-selected):hover {
  background: var(--select-search-highlight);
}

.site-wrap .select-search-is-selected {
  font-weight: bold;
}

.site-wrap .select-search-group-header {
  text-transform: uppercase;
  background: var(--select-search-border);
  color: var(--select-search-subtle-text);
  letter-spacing: .1rem;
  padding: 10px 16px;
  font-size: 12px;
}

.site-wrap .select-search-row:not(:first-child) .select-search-group-header {
  margin-top: 10px;
}

.site-wrap .select-search-row:not(:last-child) .select-search-group-header {
  margin-bottom: 10px;
}

.site-wrap .driving-teacher {
  margin-top: 50px;
  display: flex;
}

.site-wrap .driving-teacher h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.site-wrap .driving-teacher p {
  margin: 0;
}

.site-wrap .driving-teacher .teacher-image {
  width: 240px;
  height: 240px;
  margin-top: 10px;
  display: inline-block;
}

.site-wrap .driving-teacher .info {
  flex-grow: 4;
  padding-left: 20px;
  display: inline-block;
}

.site-wrap .driving-teacher .details {
  margin-top: 16px;
  font-size: 18px;
}

.site-wrap .driving-teacher .details i {
  width: 26px;
  font-size: 24px;
}

@media only screen and (width <= 691px) {
  .site-wrap .driving-teacher {
    flex-wrap: wrap;
  }

  .site-wrap .driving-teacher h2 {
    font-size: 30px;
  }

  .site-wrap .driving-teacher .info {
    padding-left: 0;
  }
}

.site-wrap .alert {
  z-index: 20;
  border: 1px solid #0000;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px 0;
  transition: all 1s;
  top: 0;
}

.site-wrap .alert-text-container {
  width: 80%;
  margin: 0 auto;
}

.site-wrap .alert h4 {
  color: inherit;
  margin-top: 0;
}

.site-wrap .alert .alert-link {
  font-weight: bold;
}

.site-wrap .alert > p, .site-wrap .alert > ul {
  margin-bottom: 0;
}

.site-wrap .alert > p + p {
  margin-top: 5px;
}

.site-wrap .alert-dismissable .close, .site-wrap .alert-dismissible .close {
  color: inherit;
  position: relative;
  top: -2px;
  right: -21px;
}

.site-wrap .alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.site-wrap .alert-success hr {
  border-top-color: #c9e2b3;
}

.site-wrap .alert-success .alert-link {
  color: #2b542c;
}

.site-wrap .alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.site-wrap .alert-info hr {
  border-top-color: #a6e1ec;
}

.site-wrap .alert-info .alert-link {
  color: #245269;
}

.site-wrap .alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.site-wrap .alert-warning hr {
  border-top-color: #f7e1b5;
}

.site-wrap .alert-warning .alert-link {
  color: #66512c;
}

.site-wrap .alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.site-wrap .alert-danger hr {
  border-top-color: #e4b9c0;
}

.site-wrap .alert-danger .alert-link {
  color: #843534;
}

.site-wrap .skraning-form .has-error {
  border-bottom: 1px solid #a94442;
}

.site-wrap .skraning-form .btn-div #submit:disabled {
  color: #efefef;
  background-color: #d3b963;
}

.site-wrap .has-error + .skraning-form-error > span {
  color: #fff;
  background-color: #d82424;
  padding: 10px 15px;
  font-weight: bold;
  position: relative;
  bottom: 30px;
  left: 330px;
}

.site-wrap .expand {
  display: block !important;
}

.site-wrap .strikethrough {
  text-decoration: line-through;
}

.site-wrap .mobile-only {
  display: none;
}

.site-wrap .sign-up-cta {
  margin-top: 9px;
  margin-right: 75px;
}

@media only screen and (width <= 1600px) {
  .site-wrap .first-section h1 {
    margin-top: 70px;
  }
}

@media only screen and (width <= 1115px) {
  .site-wrap .navbar {
    display: none;
  }

  .site-wrap .mobile-only {
    display: initial;
  }

  .site-wrap .front-page-image {
    width: 100%;
    margin-top: 0;
    margin-bottom: 80px;
    margin-right: 0;
  }

  .site-wrap .front-page-first-text {
    width: 90%;
    margin-top: 70px;
    margin-bottom: 110px;
  }

  .site-wrap .footer-item {
    width: 32%;
  }

  .site-wrap .footer-item:nth-child(3) {
    display: none;
  }

  .site-wrap .second-section .threebytwo {
    width: 49%;
    margin: 0;
  }

  .site-wrap header {
    z-index: 99;
    background-color: #eee;
    border-bottom: 1px solid #d8d8d8;
    width: 100%;
    position: fixed;
  }

  .site-wrap .main-body {
    padding-top: 20px;
  }

  .site-wrap .logo-container #logo {
    width: 200px;
    height: 40px;
    margin-top: 3px;
    margin-left: 8px;
  }

  .site-wrap .first-section h1 {
    margin-top: 20px;
  }
}

@media only screen and (width <= 770px) {
  .site-wrap .footer-row {
    width: 95%;
  }

  .site-wrap .footer-item {
    height: initial;
    float: none;
    width: 100%;
    padding-bottom: 20px;
  }

  .site-wrap .footer-row {
    padding-top: 20px;
  }

  .site-wrap .second-section .threebytwo {
    width: 99%;
    margin: 0;
  }

  .site-wrap .main-body {
    width: 95%;
  }

  .site-wrap .starfsmadur {
    width: 100%;
  }
}

@media only screen and (width <= 700px) {
  .site-wrap .third-section img {
    width: 100%;
    margin-top: 100px;
  }

  .site-wrap .third-section img:nth-child(2), .site-wrap .navbar {
    display: none;
  }

  .site-wrap .first-section {
    height: 400px;
  }

  .site-wrap .first-section h1 {
    margin-top: 30px;
    font-size: 40px;
  }

  .site-wrap .front-page-first-text {
    margin-top: 20px;
  }

  .site-wrap .front-page-first-text p {
    font-size: 20px;
  }

  .site-wrap .checkmark-container .checkmark-text {
    font-size: 18px;
  }

  .site-wrap .third-section-text-container {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .site-wrap .second-section .sales-main-text {
    font-size: 22px;
  }

  .site-wrap .second-section .threebytwo h2 {
    font-size: 25px;
  }

  .site-wrap .second-section .threebytwo p {
    font-size: 18px;
  }

  .site-wrap .second-section .threebytwo {
    height: 140px;
  }
}

@media only screen and (width <= 450px) {
  .site-wrap .logo-container #logo {
    width: 175px;
    height: auto;
    margin-top: 6px;
    margin-bottom: 4px;
  }
}

@media only screen and (width <= 391px) {
  .site-wrap .select-search-container, .site-wrap .default-text-container, .site-wrap .sign-in {
    width: 98%;
    max-width: 98%;
  }

  .site-wrap .logo-container #logo {
    width: 130px;
    height: auto;
    margin-top: 10px;
  }

  .site-wrap .sign-up-cta {
    margin-right: 50px;
  }

  .site-wrap .secondary-button {
    display: none;
  }

  .site-wrap .bottom-footer {
    height: 80px;
  }

  .site-wrap .sign-in .sign-in-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .site-wrap h1, .site-wrap .sign-in h1 {
    margin-top: 40px;
    margin-bottom: 5px;
    font-size: 2.5em;
  }
}

.site-wrap table {
  border: 1px solid gray;
  width: 100%;
  margin-bottom: 30px;
}

.site-wrap table tr {
  font-size: 18px;
}

.site-wrap table tr:nth-child(odd) {
  background-color: #e5e5e5;
}

.site-wrap table tr:nth-child(2n) {
  background-color: #eee;
}

.site-wrap table tr:last-child {
  border: 1px solid gray;
}

.site-wrap table tr td {
  padding: 8px 5px;
}

.mg-top-10 {
  margin-top: 10px;
}

.mg-top-20 {
  margin-top: 20px;
}

.mg-top-30 {
  margin-top: 30px;
}

.mg-bottom-10 {
  margin-bottom: 10px;
}

.mg-bottom-20 {
  margin-bottom: 20px;
}

.mg-bottom-30 {
  margin-bottom: 30px;
}

div.card {
  background-color: #dbdbdb;
  border: 1px solid #a9a9a9;
  padding: 20px 14px;
  box-shadow: 10px 10px 10px -12px #66666680;
}

@media only screen and (width <= 758px) {
  div.card {
    padding: 10px 10px 14px;
  }
}

#btn-component {
  color: #fff;
  background-color: #eab60b;
  border: 2px solid #eab60b;
  border-radius: 2px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
  display: inline-block;
}

#btn-component a {
  color: #fff;
}

#btn-component.btn-tiny {
  padding: 0 4px;
  font-size: 16px;
}

#btn-component.btn-small {
  padding: 2px 6px;
}

#btn-component.btn-medium {
  padding: 10px;
}

#btn-component.btn-big {
  padding: 12px;
}

#btn-component.disabled-btn {
  background-color: #dbc376;
  border: 2px solid #dbc376;
}

#btn-component.disabled-btn:hover, #btn-component.disabled-btn:focus {
  cursor: default;
  background-color: #dbc376;
  border: 2px solid #dbc376;
}

#btn-component:focus, #btn-component:hover {
  cursor: pointer;
  background-color: #cea210;
  border: 2px solid #cea210;
  outline: 0;
}

a, a:visited {
  color: #444;
  text-decoration: none;
  transition: all .1s;
}

.user-area html {
  height: 100%;
}

.user-area body {
  color: #444;
  background-color: #eee;
  height: 100%;
  margin: 0;
}

.user-area h1, .user-area h2, .user-area h3, .user-area h4, .user-area h5 {
  font-family: Bree Serif, serif;
  font-weight: 400;
}

.user-area h1 {
  border-bottom: 4px solid #eab60b;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 65px;
  display: inline-block;
}

@media only screen and (width <= 530px) {
  .user-area h1 {
    font-size: 55px;
  }
}

.user-area h2 {
  font-size: 30px;
}

.user-area h3 {
  font-size: 25px;
}

.user-area .skilti-container {
  margin-bottom: 45px;
}

.user-area .skilti-container h3 {
  margin-top: 0;
  margin-bottom: 14px;
}

.user-area .skilti-container p:first-of-type {
  margin-top: 0;
}

.user-area p, .user-area ul, .user-area a {
  font-family: Open Sans, sans-serif;
  font-size: 20px;
  line-height: 160%;
}

.user-area a {
  text-decoration: underline;
}

.user-area a:hover {
  color: gray;
}

.user-area .lotu-container .caption {
  font-size: 18px;
  font-style: italic;
}

.user-area ::selection {
  background: #eab60b;
}

.user-area ::selection {
  background: #eab60b;
}

.user-area textarea:focus, .user-area input:focus {
  outline: 0;
}

.user-area th {
  text-align: left;
  padding: 0 20px 0 0;
}

.user-area .get-help-text {
  width: 100%;
  margin-bottom: 0;
  display: block;
}

.user-area .lotu-container .get-help-text p {
  margin-bottom: 15px;
  font-size: 14px;
  display: block;
}

.user-area .main-content {
  z-index: 1;
  background-color: #eee;
  position: relative;
}

.user-area .assignment {
  border: 2px solid #4d4d4d;
  padding: 0 20px;
}

.user-area .lotu-container {
  width: 960px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.user-area .lotu-content p:first-child {
  margin-top: 0;
  top: 20px;
}

.user-area .lotu-content p {
  margin-top: 28px;
  margin-bottom: 28px;
}

.user-area .main-content .step {
  margin-top: -5px;
}

.user-area .step-test-container li, .user-area .test-container li {
  list-style-type: none;
}

.user-area .test-container h2 {
  margin-top: 0;
  padding-top: 110px;
}

.user-area .test-container li {
  width: 100%;
}

.user-area .answerRows {
  padding-left: 10px;
}

.user-area .questionRow-ul {
  padding-left: 0;
}

.user-area .questionRow-ul p {
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: bold;
}

.user-area input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.user-area .checkbox-button-container label {
  cursor: pointer;
}

.user-area .correct {
  background-color: #0080004d;
  margin-bottom: 4px;
  padding: 2px 6px;
  display: inline-block;
}

.user-area .wrong {
  background-color: #ff00004d;
  margin-bottom: 4px;
  padding: 2px 6px;
  display: inline-block;
}

.user-area .step-test-container .questionRow-ul {
  margin-top: 5px;
  margin-bottom: 5px;
}

.user-area .step-test-label {
  margin-top: 0;
  display: block;
  position: relative;
}

.user-area .lotu-container .step-test-question {
  margin-top: 18px;
  margin-bottom: 15px;
}

.user-area .fa-check {
  color: #0c5b1a;
  margin-left: 5px;
}

.user-area .fa-times {
  color: #ed0000;
  margin-left: 5px;
}

.user-area .sign-in {
  width: 362.906px;
  margin: 0 auto;
  position: relative;
}

.user-area .sign-in form {
  margin-top: 150px;
}

.user-area .sign-in .field-container {
  height: 40px;
  margin-top: 22px;
  margin-bottom: 22px;
  position: relative;
}

.user-area .sign-in .field-name {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 24px;
}

.user-area .sign-in input {
  background-color: #f9f9f9;
  border: none;
  border-bottom: 2px solid #eab60b;
  width: 342.906px;
  padding: 15px 20px 2px 4px;
  font-size: 22px;
}

.user-area .sign-in input:valid, .user-area .sign-in input:focus {
  background-color: #fff;
  border-bottom: 2px solid #eab60b;
}

.user-area .sign-in input:-webkit-autofill {
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: inset 0 0 0 50px #fff;
}

.user-area .sign-in input:-webkit-autofill:focus {
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: inset 0 0 0 50px #fff;
}

.user-area .sign-in .btn-submit {
  margin-top: 40px;
}

.user-area .btn:visited, .user-area .btn {
  color: #fff;
  background-color: #eab60b;
  border: 2px solid #eab60b;
  border-radius: 2px;
  margin-top: 10px;
  padding: 12px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
  display: inline-block;
}

.user-area .btn:visited:focus, .user-area .btn:visited:hover, .user-area .btn:focus, .user-area .btn:hover {
  cursor: pointer;
  color: #fff;
  background-color: #cea210;
  border: 2px solid #cea210;
  outline: 0;
}

.user-area .btn:visited[disabled], .user-area .btn:visited:disabled, .user-area .btn[disabled], .user-area .btn:disabled {
  cursor: not-allowed;
  background-color: #ede0b6;
  border: 1px solid #ede0b6;
}

.user-area .btn:visited[disabled] p, .user-area .btn:visited:disabled p, .user-area .btn[disabled] p, .user-area .btn:disabled p {
  display: none;
}

.user-area .btn:visited[disabled]:hover p, .user-area .btn:visited:disabled:hover p, .user-area .btn[disabled]:hover p, .user-area .btn:disabled:hover p {
  color: #444;
  opacity: .9;
  background-color: #e4e4e4;
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
  display: inline;
  position: absolute;
  top: 46px;
  left: 1px;
}

.user-area .first-btn, .user-area #previous {
  margin-right: 20px;
}

.user-area .btn.gjafab-button {
  background-color: inherit;
  color: #707070;
  border: none;
  border-bottom: 1px solid #707070;
  margin-left: 20px;
  padding-bottom: 0;
  font-weight: 400;
}

.user-area .giftcard-error {
  color: #fff;
  background-color: #963939;
  border-radius: 2px;
  margin-left: 10px;
  padding: 3px 10px;
  font-family: Bree Serif, serif;
  font-size: 20px;
  font-weight: 400;
  display: inline-block;
}

.user-area #next {
  position: relative;
  right: 0;
}

.user-area #backToMain, .user-area #goToFinalTest, .user-area #forward {
  margin-left: 20px;
}

.user-area #backToMain, .user-area #forward {
  padding: 8px;
}

.user-area .fa-arrow-right, .user-area .fa-arrow-left {
  font-size: 18px;
}

.user-area .secondary-btn, .user-area .secondary-btn:visited {
  color: #fff;
  background-color: #eab60b;
  border: 2px solid #eab60b;
  border-radius: 2px;
  margin-top: 10px;
  padding: 12px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
  display: inline-block;
}

.user-area .secondary-btn:focus, .user-area .secondary-btn:hover {
  cursor: pointer;
  color: #fff;
  background-color: #cea210;
  border: 2px solid #cea210;
  outline: 0;
}

.user-area .positive-alert {
  color: #eee;
  background-color: #3a8245;
  margin: 10px 0;
  padding: 15px 30px;
  display: inline-block;
}

.user-area .positive-alert p {
  margin: 0;
  font-family: Bree Serif, serif;
  font-size: 23px;
  font-weight: 400;
  display: inline-block;
}

.user-area .negative-alert {
  color: #eee;
  background-color: #963939;
  margin: 10px 0;
  padding: 15px 30px;
  display: inline-block;
}

.user-area .negative-alert p {
  margin: 0;
  font-family: Bree Serif, serif;
  font-size: 23px;
  font-weight: 400;
  display: inline-block;
}

.user-area .restart-course {
  margin-left: 20px;
  line-height: 160%;
  position: relative;
}

.user-area .link {
  cursor: pointer;
  font-weight: bold;
}

@media only screen and (width <= 1030px) {
  .user-area .lotu-container {
    width: 95%;
    margin: 0 auto 20px;
  }

  .user-area .lotu-content iframe, .user-area .lotu-content img {
    width: 100% !important;
  }

  .user-area .daysleft-alert-box {
    width: 100%;
  }
}

.skraning-form-error {
  color: #990404;
}

.skraning-form-error p {
  margin-top: 8px;
  font-size: 18px;
}

.user-area .message_form input {
  border: 1px solid #d3d3d3;
  border-top-color: #6d6d6d;
  width: 578px;
  padding: 14px 10px;
}

@media only screen and (width <= 758px) {
  .user-area .message_form input {
    box-sizing: border-box;
    width: 100%;
    padding: 14px 10px;
  }
}

.user-area .send-message {
  color: #fff;
  background-color: #eab60b;
  border: 2px solid #eab60b;
  border-radius: 2px;
  width: 600px;
  padding: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
  transition: all .1s;
  display: inline-block;
}

@media only screen and (width <= 758px) {
  .user-area .send-message {
    width: 100%;
  }
}

.user-area #chat-box {
  background-color: #ccc;
  width: 600px;
  height: 400px;
  margin-top: 50px;
  overflow: auto;
}

@media only screen and (width <= 758px) {
  .user-area #chat-box {
    width: 100%;
  }
}

.user-area .message {
  padding: 18px 0;
}

.user-area .message strong {
  margin-left: 10px;
}

.user-area .message .timestamp {
  margin-bottom: 5px;
  margin-left: 10px;
}

.user-area .message span:nth-child(2) {
  margin-right: 3px;
}

.dashboard-container {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

.dashboard-container .fa-check-square-o {
  color: #0c5b1a;
}

.dashboard-container .course-box {
  background-color: #dbdbdb;
  border: 1px solid #a9a9a9;
  width: 410px;
  min-height: 100px;
  margin: 20px 10px 15px;
  padding: 0 20px;
  display: inline-block;
  position: relative;
  box-shadow: 10px 10px 10px -12px #66666680;
}

@media only screen and (width <= 758px) {
  .dashboard-container .course-box {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.dashboard-container .progressbar {
  width: 410px;
  height: 25px;
  margin-top: 20px;
}

@media only screen and (width <= 758px) {
  .dashboard-container .progressbar {
    width: 100%;
  }
}

.dashboard-container .pb-section {
  height: inherit;
  box-sizing: border-box;
  border-radius: 1px;
  width: 13%;
  margin-right: 1.28571%;
  display: inline-block;
}

.dashboard-container .pb-active {
  background-color: #f2d77d;
}

.dashboard-container .pb-inactive {
  background-color: #aaa;
}

.dashboard-container .pb-finished {
  background-color: #eab60b;
}

.user-area .payment-area {
  background-color: #e5e5e5;
  padding: 20px;
  box-shadow: 2px 2px 2px #7a7a7a73;
}

.user-area .payment-area .btn {
  float: none;
}

.user-area .payment-area .valitor-sida-mynd {
  -webkit-box-shadow: 0 10px 10px -1px #66666680;
  -moz-box-shadow: 0 10px 10px -12px #66666680;
  width: 80%;
  box-shadow: 10px 10px 10px -12px #66666680;
}

.user-area .payment-area .caption {
  margin-top: 0;
  font-size: 18px;
  font-style: italic;
}

.user-area .payment-area .pick-payment-type-title {
  margin-top: 0;
}

.user-area .payment-picker {
  flex-wrap: wrap;
  display: flex;
}

.user-area .payment-picker p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.user-area .payment-picker .picked {
  box-shadow: 2px 5px 6px #7a7a7abf;
}

.user-area .payment-picker .payment-option-desc {
  font-size: 14px;
}

.user-area .payment-picker .payment-icon {
  height: 64px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.user-area .payment-picker .payment-choice {
  cursor: grab;
  text-align: center;
  background-color: #dbdbdb;
  flex-grow: 1;
  flex-basis: 0;
  height: 190px;
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 10px 20px;
  transition: all .2s;
}

@media only screen and (width <= 700px) {
  .user-area .payment-picker .payment-choice {
    width: 100%;
    height: 210px;
  }
}

.user-area .payment-picker .payment-choice:focus, .user-area .payment-picker .payment-choice:hover {
  background-color: #d6d6d6;
}

.user-area .payment-picker .payment-choice .checkbox {
  text-align: right;
}

.user-area .payment-picker .payment-choice .fa-check-square-o, .user-area .payment-picker .payment-choice .fa-square-o {
  color: #444;
  font-size: 30px;
}

.user-area .payment-picker .payment-choice p, .user-area .payment-picker .payment-choice .payment-icon {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.user-area .fa-external-link {
  position: relative;
  top: 2px;
}

.CSSTableGenerator {
  -moz-border-radius-bottomleft: 0px;
  -moz-border-radius-bottomright: 0px;
  -moz-border-radius-topright: 0px;
  -moz-border-radius-topleft: 0px;
  border: 1px solid #000;
  border-radius: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  box-shadow: 10px 10px 5px #888;
}

.CSSTableGenerator table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.CSSTableGenerator tr:last-child td:last-child {
  -moz-border-radius-bottomright: 0px;
  border-bottom-right-radius: 0;
}

.CSSTableGenerator table tr:first-child td:first-child {
  -moz-border-radius-topleft: 0px;
  border-top-left-radius: 0;
}

.CSSTableGenerator table tr:first-child td:last-child {
  -moz-border-radius-topright: 0px;
  border-top-right-radius: 0;
}

.CSSTableGenerator tr:last-child td:first-child {
  -moz-border-radius-bottomleft: 0px;
  border-bottom-left-radius: 0;
}

.CSSTableGenerator tr {
  background-color: #fff;
}

.CSSTableGenerator td {
  vertical-align: middle;
  text-align: left;
  color: #000;
  border: 1px solid #000;
  border-width: 0 1px 1px 0;
  padding: 5px;
  font-family: Arial;
  font-size: 15px;
  font-weight: normal;
}

.CSSTableGenerator tr:last-child td {
  border-width: 0 1px 0 0;
}

.CSSTableGenerator tr td:last-child {
  border-width: 0 0 1px;
}

.CSSTableGenerator tr:last-child td:last-child {
  border-width: 0;
}

.CSSTableGenerator tr:first-child td {
  background: -o-linear-gradient(#eab60b 5% 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(.05, #eab60b), to(#eab60b));
  background: -moz-linear-gradient(center top, #eab60b 5%, #eab60b 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#eab60b", endColorstr= "#eab60b");
  background: -o-linear-gradient(top, #eab60b, eab60b);
  text-align: center;
  color: #fff;
  background: -o-linear-gradient(#eab60b 5% 100%);
  background-color: #eab60b;
  border: 0 solid #000;
  border-width: 0 0 1px 1px;
  font-family: Arial;
  font-size: 22px;
  font-weight: bold;
}

.CSSTableGenerator tr:first-child:hover td {
  background: -o-linear-gradient(#eab60b 5% 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(.05, #eab60b), to(#eab60b));
  background: -moz-linear-gradient(center top, #eab60b 5%, #eab60b 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#eab60b", endColorstr= "#eab60b");
  background: -o-linear-gradient(top, #eab60b, eab60b);
  background: -o-linear-gradient(#eab60b 5% 100%);
  background-color: #eab60b;
}

.CSSTableGenerator tr:first-child td:first-child {
  border-width: 0 0 1px;
}

.CSSTableGenerator tr:first-child td:last-child {
  border-width: 0 0 1px 1px;
}

.payment-nav {
  border-bottom: 1px solid #dbdbdb;
  width: 960px;
  margin: 0 auto;
  position: relative;
  top: 10px;
}

@media only screen and (width <= 1030px) {
  .payment-nav {
    width: 95%;
  }
}

.payment-nav .payment-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  position: relative;
}

.payment-nav .payment-container .arrow {
  border: 2px solid #dbdbdb;
  border-width: 0 2px 2px 0;
  padding: 3px;
  display: inline-block;
  position: relative;
  right: 8px;
}

.payment-nav .payment-container .arrow.active-pd {
  border: 2px solid #eab60b;
  border-width: 0 2px 2px 0;
}

.payment-nav .payment-container .payment-divider {
  border-bottom: 2px solid #dbdbdb;
  flex-grow: 1;
}

.payment-nav .payment-container .payment-divider.active-pd {
  border-bottom: 2px solid #eab60b;
}

.payment-nav .payment-container .right {
  transform: rotate(-45deg);
}

.payment-nav .payment-container div {
  text-align: center;
  margin-right: 10px;
}

.payment-nav .payment-container div .payment-step-icon {
  box-sizing: initial;
  color: #999;
  box-sizing: content-box;
  background: #fcf8ea;
  border: .2em solid #dbdbdb;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  margin: 0 auto;
  font: bold 14px / 2em Arial, sans-serif;
  display: block;
}

.payment-nav .payment-container .active .payment-step-icon, .payment-nav .payment-container .finished .payment-step-icon {
  color: #eab60b;
  border: .2em solid #eab60b;
}

.user-area .navigation {
  float: left;
  z-index: 21;
  -webkit-box-shadow: 0 10px 10px -1px #66666680;
  background-color: #dbdbdb;
  border-bottom: 1px solid #cdc7c7;
  width: 100%;
  height: 80px;
  position: relative;
  top: 0;
  left: 0;
  -moz-box-shadow: 0 10px 10px -12px #66666680;
  box-shadow: 0 10px 10px -12px #66666680;
}

.user-area .navigation .logo-container #logo {
  width: 80px;
  height: 80px;
}

.user-area .navigation .sign-out-container {
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.user-area .navigation .sign-out-container a {
  color: #6d6d6d;
  font-size: 18px;
}

.user-area .navigation .sign-out-container .fa-sign-out {
  font-size: 30px;
}

.user-area .navigation .mobile-menu {
  display: none;
  position: absolute;
  top: 22px;
  right: 4px;
}

@media only screen and (width <= 958px) {
  .user-area .navigation .mobile-menu {
    display: block;
  }
}

.user-area .navigation .lotu-links {
  margin-left: 20px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (width <= 958px) {
  .user-area .navigation .lotu-links {
    display: none;
  }
}

.user-area .navigation .lotu-links .enabled-link p {
  font-weight: bold;
}

.user-area .navigation .lotu-links .disabled-link {
  pointer-events: none;
  cursor: default;
}

.user-area .navigation .lotu-links .disabled-link p {
  color: gray;
}

.user-area .navigation .lotu-links .fa {
  font-size: 18px;
}

.user-area .navigation .lotu-links .fa-check-square-o {
  color: #0c5b1a;
}

.user-area .navigation .lotu-links a {
  text-align: center;
  border-left: 2px solid #ccc;
  width: 120px;
  padding-top: 26px;
  padding-bottom: 26px;
  text-decoration: none;
  transition: all .1s;
  display: inline-block;
}

.user-area .navigation .lotu-links a:hover {
  color: #444;
  background-color: #d6d6d6;
}

.user-area .navigation .lotu-links a p {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.user-area .navigation .lotu-links .active {
  background-color: #d6d6d6;
  border-right: 2px solid #eab60b;
  font-weight: bold;
}

.user-area .navigation .lotu-links .active:hover {
  background-color: #d3d3d3;
  border-right: 2px;
}

#simple-menu a {
  color: #000;
  text-decoration: none;
}

#simple-menu a:disabled {
  color: gray;
}

#simple-menu .fa-check-square-o {
  color: #0c5b1a;
}

.lds-ring {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  border: 3px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:first-child {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.user-area .subdashboard-btn-container {
  display: block;
  position: relative;
}

.user-area .subdashboard-btn-container .fa-tachometer {
  margin-right: 8px;
}

.user-area .daysleft-alert-text-container p {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: 20px;
}

.user-area .daysleft-alert-text-container p .fa-calendar {
  margin-right: 8px;
  font-size: 28px;
}

.user-area .course-expired {
  color: #eee;
  background-color: #963939;
  margin: 5px 0;
  padding: 10px 20px;
  font-weight: bold;
}

.user-area .course-expired p {
  margin-top: 15px;
  margin-bottom: 5px;
}

.user-area .alert-text-container {
  float: right;
}

.user-area .lotu-container .time-to-round {
  width: 750px;
  margin-top: 20px;
}

@media only screen and (width <= 730px) {
  .user-area .lotu-container .time-to-round {
    text-align: center;
    width: 100%;
  }

  .user-area .lotu-container .time-to-round .fa-clock-o {
    margin: 0 auto;
    font-size: 20px;
  }
}

.user-area .lotu-container .time-to-round .fa-clock-o {
  margin-top: 13px;
  margin-right: 22px;
  font-size: 55px;
}

.user-area .lotu-container .time-to-round .alert-box {
  color: #eee;
  background-color: #3a8245;
  padding: 10px 20px;
  display: inline-block;
}

.user-area .lotu-container .time-to-round .alert-box p {
  margin: 0 0 10px;
  font-size: 14px;
}

.user-area .lotu-container .time-to-round .alert-box h2 {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 35px;
  font-size: 24px;
  display: inline-block;
}

.user-area .lotu-container .time-to-round .alert-box .main-dash-alert p {
  margin: 0;
  font-size: 16px;
}

.settings-page .text-setting span {
  color: #444;
  font-size: 18px;
}

.settings-page .text-setting .label {
  margin: 0;
  font-size: 20px;
}

.settings-page .text-setting .text-input {
  width: 230px;
  font-size: 18px;
  display: inline;
}

.settings-page .text-setting .text-input input {
  color: #444;
  width: 230px;
  margin-right: 10px;
}

.settings-page .text-setting .edit-button {
  background-color: none;
  border: none;
  margin-left: 8px;
  padding: 4px 8px;
  font-size: 16px;
  text-decoration: underline;
}

.settings-page .text-setting .loading-text {
  width: 230px;
  margin-right: 10px;
  display: inline-block;
}

.settings-page .error {
  color: #990404;
  margin-top: 5px;
  margin-bottom: 0;
}

.admin-page p {
  font-size: 16px;
}

.admin-page .student {
  margin-bottom: 10px;
}

.admin-page .student p {
  margin: 0 0 2px;
}

.user-area .feedback-box {
  z-index: 1000;
  cursor: pointer;
  color: #fff;
  background-color: #eab60b;
  border-top: 2px solid #ffe28e;
  border-bottom: 2px solid #e0ad04;
  border-left: 2px solid #ffe28e;
  padding: 5px 12px;
  position: fixed;
  bottom: 0;
  right: 0;
}

.user-area .feedback-box.open {
  color: #444;
  background-color: #e2d6b5;
}

.user-area .feedback-box.open i {
  color: #444;
}

.user-area .feedback-box .secondary-btn {
  padding: 6px;
}

.user-area .feedback-box i {
  color: #fff;
  margin-right: 5px;
}

.user-area .feedback-box p {
  margin: 0;
  font-size: 15px;
  font-weight: bolder;
}

.user-area .feedback-box textarea {
  resize: none;
  color: #444;
  border: none;
  padding: 10px 5px;
}

.user-area .feedback-box .close {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
}

.user-area .feedback-box.no-pointer {
  cursor: initial;
}

@media only screen and (width <= 600px) {
  .user-area .feedback-box {
    display: none;
  }
}

.magnet-form {
  background-color: #dbdbdb;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 0 10px 10px -12px #66666680;
}

.magnet-form h2 {
  margin-bottom: 0;
}

.magnet-form .field-container {
  margin-bottom: 20px;
}

.magnet-form .field-name {
  margin-bottom: 0;
}

.magnet-form input {
  width: 90%;
  padding: 12px;
  line-height: 20px;
}

.step-audio-player {
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 20px 0;
  padding: 15px;
  box-shadow: 0 2px 4px #0000001a;
}

.step-audio-player p {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
}

.step-audio-player audio {
  width: 100%;
  height: 40px;
}

.step-audio-player audio::-webkit-media-controls-panel {
  background-color: #d0d0d0;
}

:is(.step-audio-player audio::-webkit-media-controls-current-time-display, .step-audio-player audio::-webkit-media-controls-time-remaining-display) {
  color: #333;
}

/*# sourceMappingURL=200.css.map */
