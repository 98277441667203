// Tailwind
@import "./output.css";

// Default
@import "./reset.scss";

// Font imports
@import "./fonts.scss";

// Website
@import "./mobile-menu.scss";
@import "./front-style.scss"; // FYI: there are also imports here. Scoped to front end.
@import "./table.scss";

// Helpers
@import "./helpers.scss";

// Reusables
@import "../reusables/card/card.scss";
@import "../reusables/button/button.scss";

// Authenticated app
@import "./authenticated/style.scss";
@import "./authenticated/chat-box.scss";
@import "./authenticated/dashboard.scss";
@import "./authenticated/payment.scss";
@import "./authenticated/datatable.scss";
@import "./authenticated/navigation.scss";
@import "./authenticated/spinner.scss";
@import "./authenticated/subdashboard.scss";
@import "./authenticated/alertbox.scss";
@import "./authenticated/settings.scss";
@import "./authenticated/admin.scss";
@import "./authenticated/feedbackbox.scss";
@import "../components/courses/magnet-form.scss";
@import "../components/courses/rounds/step-audio-player.scss";
