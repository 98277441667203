/* Sidebar inside dashboard */

.payment-nav {
  position: relative;
  top: 10px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
  width: 960px;
  margin: 0 auto;
  @media only screen and (max-width: 1030px) {
    width: 95%;
  }

  .payment-container {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .arrow {
      border: solid #dbdbdb;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      position: relative;
      right: 8px;

      &.active-pd {
        border: solid #eab60b;
        border-width: 0 2px 2px 0;
      }
    }

    .payment-divider {
      flex-grow: 1;
      border-bottom: 2px solid #dbdbdb;

      &.active-pd {
        border-bottom: 2px solid #eab60b;
      }
    }

    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    div {
      text-align: center;
      margin-right: 10px;

      .payment-step-icon {
        display: block;
        margin: 0 auto;

        font:
          14px Arial,
          sans-serif;
        width: 2em;
        height: 2em;
        box-sizing: initial;

        background: #fcf8ea;
        border: 0.2em solid #dbdbdb;
        font-weight: bold;
        color: #999999;
        border-radius: 50%;

        line-height: 2em;
        box-sizing: content-box;
      }
    }
    .active {
      .payment-step-icon {
        color: #eab60b;
        border: 0.2em solid #eab60b;
      }
    }

    .finished {
      .payment-step-icon {
        color: #eab60b;
        border: 0.2em solid #eab60b;
      }
    }
  }
}

.user-area .navigation {
  height: 80px;
  background-color: #dbdbdb;
  width: 100%;
  float: left;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 21;
  border-bottom: 1px solid #cdc7c7;
  -webkit-box-shadow: 0px 10px 10px -1px rgba(102, 102, 102, 0.5);
  -moz-box-shadow: 0px 10px 10px -12px rgba(102, 102, 102, 0.5);
  box-shadow: 0px 10px 10px -12px rgba(102, 102, 102, 0.5);

  .logo-container #logo {
    width: 80px;
    height: 80px;
  }

  .sign-out-container {
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 20px;

    a {
      font-size: 18px;
      color: #6d6d6d;
    }

    .fa-sign-out {
      font-size: 30px;
    }
  }

  .mobile-menu {
    display: none;
    position: absolute;
    top: 22px;
    right: 4px;

    @media only screen and (max-width: 958px) {
      display: block;
    }
  }

  .lotu-links {
    display: inline-block;
    margin-left: 20px;
    position: absolute;
    top: 0;
    right: 0;

    @media only screen and (max-width: 958px) {
      display: none;
    }

    .enabled-link p {
      font-weight: bold;
    }

    .disabled-link {
      pointer-events: none;
      cursor: default;
      p {
        color: grey;
      }
    }

    // Lock, square and checkbox icons
    .fa {
      font-size: 18px;
    }

    .fa-check-square-o {
      color: #0c5b1a;
    }

    a {
      display: inline-block;
      width: 120px;
      padding-top: 26px;
      padding-bottom: 26px;
      text-align: center;
      transition: 0.1s all;
      border-left: 2px solid #cccccc;
      text-decoration: none;

      &:hover {
        color: #444;
        background-color: #d6d6d6;
      }

      p {
        margin: 0;
        padding: 0;
        font-size: 18px;
      }
    }

    // To do, make this work.
    .active {
      background-color: #d6d6d6;
      border-right: 2px solid #eab60b;
      font-weight: bold;
      &:hover {
        background-color: #d3d3d3;
        border-right: 2;
      }
    }
  }
}

#simple-menu {
  a {
    color: black;
    text-decoration: none;
  }

  a:disabled {
    color: grey;
  }

  .fa-check-square-o {
    color: #0c5b1a;
  }
}
