/* Payment picker */
.user-area {
  .payment-area {
    background-color: #e5e5e5;
    -webkit-box-shadow: 2px 2px 2px 0px rgba(122, 122, 122, 0.45);
    -moz-box-shadow: 2px 2px 2px 0px rgba(122, 122, 122, 0.45);
    box-shadow: 2px 2px 2px 0px rgba(122, 122, 122, 0.45);
    padding: 20px;

    .btn {
      float: none;
    }

    .valitor-sida-mynd {
      width: 80%;
      -webkit-box-shadow: 0px 10px 10px -1px rgba(102, 102, 102, 0.5);
      -moz-box-shadow: 0px 10px 10px -12px rgba(102, 102, 102, 0.5);
      box-shadow: 10px 10px 10px -12px rgba(102, 102, 102, 0.5);
    }

    .caption {
      margin-top: 0;
      font-style: italic;
      font-size: 18px;
    }

    .pick-payment-type-title {
      margin-top: 0;
    }
  }

  .payment-picker {
    display: flex;
    flex-wrap: wrap;

    p {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .picked {
      -webkit-box-shadow: 2px 5px 6px 0px rgba(122, 122, 122, 0.75);
      -moz-box-shadow: 2px 5px 6px 0px rgba(122, 122, 122, 0.75);
      box-shadow: 2px 5px 6px 0px rgba(122, 122, 122, 0.75);
    }

    .payment-option-desc {
      font-size: 14px;
    }

    .payment-icon {
      height: 64px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .payment-choice {
      flex-grow: 1;
      height: 190px;
      background-color: #dbdbdb;
      margin-right: 20px;
      cursor: grab;
      flex-basis: 0;
      padding: 10px 20px;
      text-align: center;
      transition: 0.2s all;
      margin-bottom: 20px;

      @media only screen and (max-width: 700px) {
        width: 100%;
        height: 210px;
      }

      &:focus,
      &:hover {
        background-color: #d6d6d6;
      }

      .checkbox {
        text-align: right;
      }

      .fa-check-square-o,
      .fa-square-o {
        font-size: 30px;
        color: #444444;
      }

      p,
      .payment-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .fa-external-link {
    top: 2px;
    position: relative;
  }
}
