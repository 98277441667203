.faq-section {
  background-color: #e5e5e5;
  padding: 40px 0;
  margin: 40px 0;
  border-radius: 8px;

  h2 {
    font-size: 32px;
    margin-bottom: 40px;
    text-align: center;
  }

  .faq-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .faq-item {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    &:hover {
      background: #f8f8f8;
    }

    &.open {
      background: white;
      .faq-icon {
        background-color: #eab60b;
        color: white;
      }
    }
  }

  .faq-question {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      color: #444;
      font-family: "Open Sans", sans-serif;
    }

    .faq-icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      transition: all 0.3s ease;
    }
  }

  .faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    background: #f8f8f8;

    p {
      margin: 0;
      padding: 20px;
      font-size: 16px;
      line-height: 1.6;
      color: #444;
      font-family: "Open Sans", sans-serif;
    }
  }

  .faq-item.open .faq-answer {
    max-height: 300px;
  }
}

@media only screen and (max-width: 900px) {
  .faq-section {
    padding: 30px 20px;
    margin: 30px 0;

    .faq-grid {
      grid-template-columns: 1fr;
    }

    h2 {
      font-size: 32px;
    }
  }
}
