.user-area {
	/*******************************/
	/* Sub-Dashboard
	/*******************************/
	.subdashboard-btn-container {
		display: block;
		position: relative;
	}

	.subdashboard-btn-container .fa-tachometer {
		margin-right: 8px;
	}

	.daysleft-alert-text-container p {
		margin-top: 0;
		margin-bottom: 0;
		padding: 0;
		font-size: 20px;

		.fa-calendar {
			font-size: 28px;
			margin-right: 8px;
		}
	}
}
