.user-area {
	.message_form input {
		width: 578px;
		padding: 14px 10px;
		border: 1px solid lightgrey;
		border-top: 1px solid #6d6d6d;

		@media only screen and (max-width: 758px) {
			width: 100%;
			box-sizing: border-box;
			padding: 14px 10px;
		}
	}

	.send-message {
		width: 600px;
		padding: 8px;
		display: inline-block;
		border-radius: 2px;
		font-family: "Montserrat", sans-serif;
		border: 2px solid #eab60b;
		background-color: #eab60b;
		font-size: 16px;
		font-weight: 600;
		color: white;
		transition: 0.1s all;

		@media only screen and (max-width: 758px) {
			width: 100%;
		}
	}

	#chat-box {
		height: 400px;
		width: 600px;
		margin-top: 50px;
		overflow: auto;
		background-color: #cccccc;

		@media only screen and (max-width: 758px) {
			width: 100%;
		}
	}

	.message {
		padding: 18px 0px;

		strong {
			margin-left: 10px;
		}

		.timestamp {
			margin-left: 10px;
			margin-bottom: 5px;
		}

		span:nth-child(2) {
			margin-right: 3px;
		}
	}
}
