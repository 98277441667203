#btn-component {
  display: inline-block;
  border-radius: 2px;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #eab60b;
  background-color: #eab60b;
  font-size: 20px;
  font-weight: 600;
  color: white;
  transition: 0.1s all;
  text-decoration: none;

  a {
    color: white;
  }

  &.btn-tiny {
    padding: 0px 4px;
    font-size: 16px;
  }

  &.btn-small {
    padding: 2px 6px;
  }

  &.btn-medium {
    padding: 10px;
  }

  &.btn-big {
    padding: 12px;
  }

  &.disabled-btn {
    border: 2px solid #dbc376;
    background-color: #dbc376;

    &:hover,
    &:focus {
      cursor: default;
      border: 2px solid #dbc376;
      background-color: #dbc376;
    }
  }
}

#btn-component:focus,
#btn-component:hover {
  border: 2px solid #cea210;
  background-color: #cea210;
  outline: 0;
  cursor: pointer;
}
