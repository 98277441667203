.salespage-hero-section {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 60px;

  .first-box {
    min-width: 55%;
  }

  .second-box {
    min-width: 40%;
    margin-left: 20px;
    margin-top: 20px;
    flex: 1;

    @media only screen and (max-width: 770px) {
      margin-left: 0;
      height: 180px;
    }

    .main-image {
      width: 100%;
      height: 100%;
      max-height: 380px;
      object-fit: cover;
      object-position: left;
    }
  }

  .points {
    .fa {
      color: #cea210;
    }

    p {
      margin-top: 0;
      margin-bottom: 6px;
    }
  }

  @media only screen and (max-width: 770px) {
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
