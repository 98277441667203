.user-area .feedback-box {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 1000;

	// Cursor
	cursor: pointer;

	// Design
	color: white;
	background-color: #eab60b;
	border-left: 2px solid #ffe28e;
	border-top: 2px solid #ffe28e;
	border-bottom: 2px solid #e0ad04;

	&.open {
		background-color: #e2d6b5;
		color: #444444;
		i {
			color: #444444;
		}
	}

	.secondary-btn {
		padding: 6px;
	}

	padding: 5px 12px;

	i {
		margin-right: 5px;
		color: white;
	}

	p {
		font-size: 15px;
		margin: 0;
		font-weight: bolder;
	}

	textarea {
		border: none;
		resize: none;
		color: #444444;
		padding: 10px 5px;
	}

	.close {
		top: 5px;
		right: 10px;
		position: absolute;
		cursor: pointer;
	}

	&.no-pointer {
		cursor: initial;
	}

	@media only screen and (max-width: 600px) {
		display: none;
	}
}
