.blog-text {
	max-width: 800px;
	margin: 0 auto;

	img {
		max-width: 100%;
	}

	.image-caption {
		font-size: 14px;
		margin-top: 0px;
		margin-bottom: 40px;
		display: block;
		font-style: italic;
	}

	a {
		text-decoration: underline;
	}

	p {
		font-size: 20px;
	}

	ul li {
		list-style-type: disc;
	}

	// Titles
	h1 {
		border-bottom: none;
		margin-bottom: 10px;
	}

	h2 {
		font-size: 40px;
		margin-top: 80px;
		margin-bottom: 20px;
	}

	h3 {
		font-size: 30px;
		margin-top: 60px;
		margin-bottom: 10px;
	}

	.sub-title {
		font-size: 20px;
		margin-bottom: 50px;
		display: block;
		font-style: italic;
	}

	.last-updated {
		font-size: 14px;
	}
}
