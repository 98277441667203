.step-audio-player {
  background-color: #e0e0e0;
  padding: 15px;
  border-radius: 4px;
  margin: 20px 0;
  box-shadow: 0 2px 4px #0000001a;

  p {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 14px;
  }

  audio {
    width: 100%;
    height: 40px;

    &::-webkit-media-controls-panel {
      background-color: #d0d0d0;
    }

    &::-webkit-media-controls-current-time-display,
    &::-webkit-media-controls-time-remaining-display {
      color: #333;
    }
  }
}
