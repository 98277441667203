/* Dashboard */

.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .fa-check-square-o {
    color: #0c5b1a;
  }

  .course-box {
    width: 410px;
    margin-right: 10px;
    margin-left: 10px;
    min-height: 100px;
    display: inline-block;
    border: 1px solid darkgrey;
    background-color: #dbdbdb;
    padding: 0px 20px;
    position: relative;
    margin-bottom: 15px;
    margin-top: 20px;
    -webkit-box-shadow: 10px 10px 10px -12px rgba(102, 102, 102, 0.5);
    -moz-box-shadow: 10px 10px 10px -12px rgba(102, 102, 102, 0.5);
    box-shadow: 10px 10px 10px -12px rgba(102, 102, 102, 0.5);

    @media only screen and (max-width: 758px) {
      margin-right: 0px;
      margin-left: 0px;
      width: 100%;
    }
  }

  /* Progress bar */
  .progressbar {
    width: 410px;
    height: 25px;
    margin-top: 20px;

    @media only screen and (max-width: 758px) {
      width: 100%;
    }
  }

  .pb-section {
    width: 13%;
    margin-right: 1.285714286%;
    height: inherit;
    display: inline-block;
    border-radius: 1px;
    box-sizing: border-box;
  }

  .pb-active {
    background-color: #f2d77d;
  }

  .pb-inactive {
    background-color: #aaaaaa;
  }

  .pb-finished {
    background-color: #eab60b;
  }
}
