.social-proof {
  text-align: center;
  padding: 40px 1rem;
  padding-bottom: 60px;
  background-color: #e5e5e5;
  margin: 40px 0;
  border-radius: 8px;

  h2 {
    margin-bottom: 40px;
    font-size: 32px;
  }

  .testimonials {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;

    .testimonial {
      flex: 1;
      min-width: 300px;
      max-width: 450px;

      .testimonial-content {
        background: white;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

        p {
          font-size: 22px;
          line-height: 1.6;
          margin-bottom: 20px;
          font-style: italic;
        }

        .testimonial-author {
          font-size: 21px;
          font-weight: 600;
        }
      }
    }
  }
}

// Responsive adjustments
@media only screen and (max-width: 770px) {
  .social-proof {
    padding: 30px 1rem;
    margin: 30px 0;

    h2 {
      margin-bottom: 30px;
    }

    .testimonials {
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .testimonial {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

/*******************************/
/* Sales
/********************************/

.sales-page p {
  font-size: 22px;
}

.sales-page ul li {
  font-size: 21px;
  list-style-type: square;
}

.sales-page h2 {
  margin-top: 40px;
}

.steering-wheel {
  width: 400px;
  margin-bottom: 40px;
  float: left;
  margin-right: 40px;
  border-radius: 500px;
}

.img-and-info {
  width: 100%;
  margin-top: 40px;
}

.screenshots {
  width: 48%;
  margin-top: 40px;
  margin-bottom: 20px;
}

.first-screenshot {
  margin-right: 4%;
}

@media only screen and (max-width: 770px) {
  .steering-wheel {
    display: none;
  }

  .img-and-info {
    margin-bottom: 20px;
  }
}
