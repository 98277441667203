.driving-teacher {
	margin-top: 50px;
	display: flex;

	h2 {
		margin-top: 0;
		margin-bottom: 0;
	}

	p {
		margin: 0;
	}

	.teacher-image {
		margin-top: 10px;
		display: inline-block;
		width: 240px;
		height: 240px;
	}

	.info {
		padding-left: 20px;
		display: inline-block;
		flex-grow: 4;
	}

	.details {
		font-size: 18px;
		margin-top: 16px;
		i {
			font-size: 24px;
			width: 26px;
		}
	}

	@media only screen and (max-width: 691px) {
		flex-wrap: wrap;
		h2 {
			font-size: 30px;
		}
		.info {
			padding-left: 0px;
		}
	}
}
