div.card {
  background-color: #dbdbdb;
  border: 1px solid #a9a9a9;
  padding: 20px 14px;
  box-shadow: 10px 10px 10px -12px #66666680;

  @media only screen and (max-width: 758px) {
    padding: 10px;
    padding-bottom: 14px;
  }
}
