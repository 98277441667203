.admin-page {
	p {
		font-size: 16px;
	}

	.student {
		margin-bottom: 10px;
		p {
			margin: 0;
			margin-bottom: 2px;
		}
	}
}
